import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/typography';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {Title} from 'components/DSYS/Title';
import {ReactNode} from 'react';

export const DashboardsChartContainer = ({
  title,
  subtitle,
  icon,
  style,
  children,
}: {
  title: string;
  subtitle: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  children: ReactNode;
  style?: BoxProps<'div'>['style'];
}) => {
  return (
    <Box
      as="div"
      marginTop="8"
      marginBottom="24"
      paddingTop="16"
      paddingRight="16"
      paddingBottom="24"
      paddingLeft="20"
      borderRadius="Medium"
      borderColor="Border Subtle"
      borderStyle="Solid"
      borderWidth="1"
      style={style}
    >
      <LabelGroup
        withLeftAccessory={<UIIcon src={icon} width={24} height={24} />}
        withText={<Title size={18}>{title}</Title>}
      />
      <Box marginTop="2">
        <Text size="small" color="subtle">
          {subtitle}
        </Text>
      </Box>
      {children}
    </Box>
  );
};
