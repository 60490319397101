import {Text} from '@dropbox/dig-components/dist/typography';
import {CURRENT_YEAR, GROUPED_TIMEFRAME_OPTIONS, TIMEFRAME_OPTIONS} from 'constant';
import {differenceInMinutes, formatDistanceToNow, isToday, parseISO} from 'date-fns';
import {t} from 'i18next';

export const calculateTimeAgo = (timestamp: string) => {
  // Need to add 'Z' to the end of the timestamp to make it UTC
  const date = parseISO(timestamp + 'Z');

  const isJustNow = differenceInMinutes(new Date(), date) < 1;
  const timePeriod = formatDistanceToNow(date);
  const time = isToday(date)
    ? `${timePeriod} ago`
    : date.toLocaleString('default', {month: 'short', day: 'numeric'});

  const timeWithoutAbout = time.replace('about ', '');

  return isJustNow ? t('just_now') : timeWithoutAbout;
};

export const isPastDue = (timestamp?: string | null) => {
  if (!timestamp) {
    return false;
  }
  const date = parseISO(timestamp);
  return date < new Date();
};

export const toLocaleString = (timestamp: string) => {
  const date = parseISO(timestamp);
  return date.toLocaleString('default', {month: 'short', day: 'numeric', year: 'numeric'});
};

export const toLocaleStringConditionalYear = (timestamp: string) => {
  const date = parseISO(timestamp);

  if (date.getFullYear() === new Date().getFullYear()) {
    return date.toLocaleString('default', {month: 'short', day: 'numeric'});
  }

  return date.toLocaleString('default', {month: 'short', day: 'numeric', year: 'numeric'});
};

export const parseServerDate = (timestamp?: string | null) => {
  if (!timestamp) {
    return new Date();
  }
  return parseISO(timestamp + 'Z');
};

export const toQuarter = (timestamp: string) => {
  const date = parseISO(timestamp);
  return `Q${Math.floor(date.getMonth() / 3) + 1}`;
};

export const getPreviousQuarter = (currentTimeframe: string) => {
  const currentQuarterIndex = Object.keys(TIMEFRAME_OPTIONS).findIndex(
    (timeframe) => timeframe === currentTimeframe
  );
  const previousQuarter =
    currentQuarterIndex > 0 ? Object.keys(TIMEFRAME_OPTIONS)[currentQuarterIndex - 1] : '';
  return previousQuarter;
};

export const getNextQuarter = (currentTimeframe: string) => {
  const currentQuarterIndex = Object.keys(TIMEFRAME_OPTIONS).findIndex(
    (timeframe) => timeframe === currentTimeframe
  );
  const nextQuarter =
    currentQuarterIndex >= 0 && currentQuarterIndex < Object.keys(TIMEFRAME_OPTIONS).length - 1
      ? Object.keys(TIMEFRAME_OPTIONS)[currentQuarterIndex + 1]
      : '';
  return nextQuarter;
};

export const parseTimeframe = (timeframe: string) => {
  const [quarterString, yearString] = timeframe.replace('FY', '20').split(' ');
  const quarterMap: Record<string, number> = {Q1: 1, Q2: 2, Q3: 3, Q4: 4};
  const quarterNum = quarterMap[quarterString];
  const yearNum = parseInt(yearString);
  return {quarterNum, quarterString, yearNum, yearString};
};

export const getTimeframeOptions = (currentTimeframe?: string) => {
  if (!currentTimeframe) {
    return [];
  }

  const {quarterNum: currentQuarterNum, yearNum: currentYearNum} = parseTimeframe(currentTimeframe);
  // Remove quarters that are past current timeframe
  let options = Object.keys(TIMEFRAME_OPTIONS).slice(0, 5);
  options = options.filter((option) => {
    const {quarterNum, yearNum} = parseTimeframe(option);
    return (
      yearNum > currentYearNum || (yearNum === currentYearNum && quarterNum > currentQuarterNum)
    );
  });
  return options;
};

export const getTimeframeButtonText = (timeframe: string) => {
  const {quarterString, yearNum} = parseTimeframe(timeframe);
  return yearNum === CURRENT_YEAR ? quarterString : `${quarterString} ${yearNum}`;
};

export const isCurrentQuarter = (currentTimeframe: string) => {
  const currentQuarter = Object.values(GROUPED_TIMEFRAME_OPTIONS.current)
    .flat()
    .filter(({date}) => date.toLowerCase().indexOf('ends') !== -1)
    .map(({timeframe}) => timeframe);
  return currentQuarter.includes(currentTimeframe);
};

export const isPreviousQuarter = (currentTimeframe: string) => {
  const previousQuarters = Object.values(GROUPED_TIMEFRAME_OPTIONS.previous)
    .flat()
    .map(({timeframe}) => timeframe);
  const endedQuarters = Object.values(GROUPED_TIMEFRAME_OPTIONS.current)
    .flat()
    .filter(({date}) => date.toLowerCase().indexOf('ended') !== -1)
    .map(({timeframe}) => timeframe);
  const pastQuarters = [...previousQuarters, ...endedQuarters];
  return pastQuarters.includes(currentTimeframe);
};

const toLocaleStringWithDay = (timestamp: string) => {
  const date = parseISO(timestamp + 'Z');
  return date.toLocaleString('default', {weekday: 'long', month: 'short', day: 'numeric'});
};

export const DueIn = ({timestamp}: {timestamp: string}) => (
  <Text color={isPastDue(timestamp) ? 'error' : 'faint'} size="small">
    Due {toLocaleStringWithDay(timestamp)}
  </Text>
);

export const TimeAgo = ({timestamp}: {timestamp: string}) => {
  return (
    <Text color="faint" size="small">
      {calculateTimeAgo(timestamp)}
    </Text>
  );
};

export const formatTimeframe = (timeframe: string) => {
  const timeframeTokens = timeframe.split(' ');
  if (timeframeTokens.length !== 2) {
    return timeframe;
  }
  const quarter = timeframeTokens[0];
  const year = timeframeTokens[1].replace('FY', '20');
  return `${quarter} ${year}`;
};
