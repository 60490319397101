import {WrapperRefObject} from '@dropbox/dig-components/dist/menu';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {growthbookCacheAtom} from 'atoms/layout';
import {Title} from 'components/DSYS/Title';
import {TableSearch} from 'components/shared/table/TableSearch';
import {STEAM_TEAM_SLUG} from 'constant';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {LegacyRef} from 'react';

import {FilterChips, TableFilter} from './Table/TableFilter';
import {TimeframeSelector} from './TimeframeSelector';
import {GoalsHeaderData, GoalsTableFilter, SelectedKey} from './types';
import {
  getGoalsCount,
  hasPrimaryFiltersSelected,
  hasTooManyGoals,
  MAX_NUM_GOALS_TO_SHOW,
} from './utils';

const isGoalsHeaderFullyLoaded = (filter?: GoalsTableFilter, goalsHeaderData?: GoalsHeaderData) => {
  if (!filter) {
    return true;
  }
  if (!goalsHeaderData) {
    return false;
  }
  const {goalsCountMap, filteredGoalsCountMap, directReportLdaps, sTeamLdaps, teamsMap} =
    goalsHeaderData;
  const isPeopleLoading = !!filter.people.some(
    (ldap) => goalsCountMap[ldap] === undefined || filteredGoalsCountMap[ldap] === undefined
  );
  if (isPeopleLoading) {
    return false;
  }
  const isDirectReportsLoading = filter.reports == '1' && !directReportLdaps;
  if (isDirectReportsLoading) {
    return false;
  }
  const isTeamLoading = !!filter.teams.some(
    ({slug}) =>
      slug &&
      slug !== STEAM_TEAM_SLUG &&
      (teamsMap?.[slug] === undefined ||
        goalsCountMap[slug] === undefined ||
        filteredGoalsCountMap[slug] === undefined)
  );
  if (isTeamLoading) {
    return false;
  }
  const filterTeamSlugs = filter.teams.map(({slug}) => slug ?? '').filter(Boolean);
  const isCompanyLoading = filterTeamSlugs.includes(STEAM_TEAM_SLUG) && !sTeamLdaps;
  if (isCompanyLoading) {
    return false;
  }
  return true;
};

const getFilterWithTeamInfo = (
  filter?: GoalsTableFilter,
  teamsMap?: GoalsHeaderData['teamsMap']
) => {
  if (!filter?.teams?.length || !teamsMap) {
    return filter;
  }
  const filterWithTeamInfo = {
    ...filter,
    teams: filter.teams
      .map((team) => {
        if (team.slug === STEAM_TEAM_SLUG || !!team.dbx_team_id) {
          return team;
        }
        return teamsMap[team.slug ?? ''];
      })
      .filter(Boolean),
  };
  return filterWithTeamInfo;
};

export const GoalsHeaderSkeleton = (boxProps: BoxProps<'div'>) => {
  const width = 100;
  return (
    <Box {...boxProps} style={{width}}>
      <Skeleton.Box width={width} height={24} />
    </Box>
  );
};

export const GoalsHeader = ({
  timeframe,
  setTimeframe,
  filter,
  setFilter,
  quickFilterSelected,
  handleQuickFilterSelected,
  containerStyleProps = {},
  disableCountHeader = false,
  titleOverride,
  filterButtonRef,
  disableStickyHeader,
  goalsHeaderData = {goalsCountMap: {}, filteredGoalsCountMap: {}, teamsMap: {}},
}: {
  timeframe: string;
  setTimeframe: (timeframe: string) => void;
  filter?: GoalsTableFilter;
  setFilter?: (filter: GoalsTableFilter, shouldNotUpdateUrl?: boolean) => void;
  quickFilterSelected?: SelectedKey;
  handleQuickFilterSelected?: (selectedItem: SelectedKey) => void;
  containerStyleProps?: {[key: string]: any};
  disableCountHeader?: boolean;
  titleOverride?: string;
  filterButtonRef?: LegacyRef<WrapperRefObject>;
  disableStickyHeader?: boolean;
  goalsHeaderData?: GoalsHeaderData;
}) => {
  const {isDashboardsEnabled} = useAtomValue(growthbookCacheAtom);
  const {
    goalsCountMap,
    filteredGoalsCountMap,
    directReportLdaps,
    sTeamLdaps,
    teamsMap,
    tableMaxGoalsLimit,
  } = goalsHeaderData;
  const filterWithTeamInfo = getFilterWithTeamInfo(filter, teamsMap);
  const numTotalGoals = getGoalsCount(
    goalsCountMap,
    filterWithTeamInfo,
    directReportLdaps,
    sTeamLdaps
  );
  const filteredCount = getGoalsCount(
    filteredGoalsCountMap,
    filterWithTeamInfo,
    directReportLdaps,
    sTeamLdaps
  );
  let title = 'table_goals';
  if (filteredCount !== numTotalGoals) {
    title = 'table_goals_filter';
  }
  const showFilter = filterWithTeamInfo && setFilter;

  const renderCountHeader = () => {
    if (
      hasTooManyGoals(goalsHeaderData, isDashboardsEnabled, filterWithTeamInfo, filteredCount) ||
      (filterWithTeamInfo && !hasPrimaryFiltersSelected(filterWithTeamInfo))
    ) {
      return (
        <Title size={18} style={{width: 200}}>
          {t('more_goals', {
            count:
              isDashboardsEnabled && tableMaxGoalsLimit
                ? tableMaxGoalsLimit
                : MAX_NUM_GOALS_TO_SHOW,
          })}
        </Title>
      );
    }
    if (isDashboardsEnabled && tableMaxGoalsLimit && numTotalGoals >= tableMaxGoalsLimit) {
      return (
        <Title size={18} style={{width: 200}}>
          {t('dashboards_table_goals_filter', {count: numTotalGoals, filteredCount})}
        </Title>
      );
    }
    return (
      <Title size={18} style={{width: 200}}>
        {t(title, {count: numTotalGoals, filteredCount})}
      </Title>
    );
  };

  const goalsHeaderCount = !disableCountHeader ? (
    renderCountHeader()
  ) : titleOverride ? (
    <Text variant="label" size="xlarge" isBold>
      {t(titleOverride)}
    </Text>
  ) : (
    <Box style={{width: 200}} />
  );

  return (
    <>
      <Box
        data-testid="goals-header"
        position="sticky"
        backgroundColor="Background Base"
        marginTop="4"
        paddingTop="12"
        paddingBottom="10"
        width="100%"
        style={!disableStickyHeader ? {top: 52, zIndex: 2} : undefined}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{maxWidth: 1240, ...containerStyleProps}}
        >
          {isGoalsHeaderFullyLoaded(filterWithTeamInfo, goalsHeaderData) ? (
            goalsHeaderCount
          ) : (
            <GoalsHeaderSkeleton />
          )}

          <Box display="flex">
            {showFilter && (
              <>
                {quickFilterSelected && (
                  <FilterChips
                    filter={filterWithTeamInfo}
                    setFilter={setFilter}
                    handleQuickFilterSelected={handleQuickFilterSelected}
                  />
                )}
                <TableFilter
                  filter={filterWithTeamInfo}
                  setFilter={setFilter}
                  handleQuickFilterSelected={handleQuickFilterSelected!}
                  filterButtonRef={filterButtonRef}
                />
              </>
            )}
            {showFilter && (
              <Box paddingRight="8">
                <TableSearch
                  placeholder={t('filter_by_name')}
                  filter={filterWithTeamInfo}
                  setFilter={setFilter}
                />
              </Box>
            )}
            <Box
              paddingLeft="16"
              marginTop="4"
              borderLeft={showFilter ? 'Solid' : undefined}
              borderColor={showFilter ? 'Border Subtle' : undefined}
              height="fit-content"
              style={{height: '24px'}}
            >
              <TimeframeSelector
                selectedTimeframe={timeframe}
                setSelectedTimeframe={(next) => {
                  analyticsLogger().logEvent('GOAL_TABLE_TIMEFRAME', {timeframe: next});
                  setTimeframe(next);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
