import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Stack} from '@dropbox/dig-foundations';
import {MegaphoneMini, SpeechBubblesMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {PulseService} from 'client';
import {ButtonLink} from 'components/DSYS/Link';
import {getActivePulse} from 'components/notifications/utils/findNotification';
import {getService} from 'utilities';

import styles from './EmptyPulse.module.css';
import {PulseQuestionBox} from './PulseQuestionBox';

export const EmptyPulse = ({inProgress}: {inProgress?: boolean}) => {
  const openPulse = getActivePulse();
  const {data: active} = useQuery({
    queryKey: ['pulse', 'active'],
    queryFn: getService(PulseService).activeApiV1PulseActiveGet,
  });

  return (
    <Stack gap="16">
      {active && !openPulse && (
        <Box>
          {active.map(({question, response}) => (
            <PulseQuestionBox
              key={question.id}
              pillar={'people-team'}
              question={question}
              index={0}
              response={response}
              responseCounts={[]}
              metrics={[]}
            />
          ))}
        </Box>
      )}
      <Box
        width="100%"
        paddingTop="68"
        paddingBottom="68"
        display="flex"
        borderRadius="Medium"
        borderStyle="Solid"
        borderColor="Border Subtle"
        textAlign="center"
        placeItems="center"
        marginTop="12"
        marginBottom="44"
      >
        <Box as={Stack} gap="8" style={{maxWidth: 380}}>
          {!inProgress ? (
            <>
              <Box className={styles.noPulse}>
                <SpeechBubblesMini width={64} altText="" />
              </Box>
              <Text size="large" isBold>
                No active pulse survey right now
              </Text>
              <Box as={Text} display="block" color="Text Subtle">
                We will let you know when the next one is sent.
              </Box>
            </>
          ) : !openPulse ? (
            <>
              <Box className={styles.noPulse}>
                <SpeechBubblesMini width={64} altText="" />
              </Box>
              <Text size="large" isBold>
                Thanks for participating in this pulse survey!
              </Text>
              <Box as={Text} display="block" color="Text Subtle">
                We will let you know when the results are available.
              </Box>
            </>
          ) : (
            <>
              <Box className={styles.megaphone}>
                <MegaphoneMini width={64} altText="" />
              </Box>
              <Text size="large" isBold>
                Complete this month&apos;s pulse survey
              </Text>
              <Box as={Text} display="block" marginTop="2" color="Text Subtle">
                1 minute is all it takes and participants can view the results when they become
                available.
              </Box>
              <Box paddingTop="6">
                <ButtonLink variant="opacity" size="small" to={openPulse.data.to}>
                  Get started
                </ButtonLink>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Stack>
  );
};
