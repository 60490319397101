import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {Title} from 'components/DSYS/Title';
import {ReactNode} from 'react';

export const Section = ({
  title,
  withAccessoryStart,
  withAccessoryEnd,
  isLoading,
  loadingSkeleton,
  content,
  gap = '12',
  className,
  style,
}: {
  title: ReactNode;
  withAccessoryStart?: ReactNode;
  withAccessoryEnd?: ReactNode;
  gap?: string;
  isLoading: boolean;
  loadingSkeleton?: ReactNode;
  content: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <Box
      as={Stack}
      gap={gap}
      paddingX="20"
      paddingY="16"
      borderRadius="Medium"
      height="100%"
      borderStyle="Solid"
      borderColor="Border Subtle"
      backgroundColor="Background Base"
      position="relative"
    >
      <Text>
        <Split gap="4" alignY="center">
          {withAccessoryStart && (
            <Box as={Split.Item} display="flex">
              {withAccessoryStart}
            </Box>
          )}
          <Split.Item width="fill" style={{width: '100%'}}>
            <Title size={16}>{title}</Title>
          </Split.Item>
          {withAccessoryEnd && (
            <Split.Item style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {withAccessoryEnd}
            </Split.Item>
          )}
        </Split>
      </Text>

      <Box style={style} className={className}>
        {isLoading ? (loadingSkeleton ?? content) : content}
      </Box>
    </Box>
  );
};
