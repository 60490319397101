import {Button} from '@dropbox/dig-components/dist/buttons';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {showSearchAtom} from 'atoms/search';
import {EmployeeAnniversary, EmployeeBirthday} from 'client';
import {Layout} from 'components/DSYS/Layout';
import ProfileCard from 'components/shared/ProfileCard';
import {sortEmployees} from 'helpers/utils';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {usePeople} from 'hooks/usePeople';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {Suspense, useState} from 'react';

import styles from './People.module.css';

const MAX_PEOPLE = 12;

const getYearsAtCompany = (tenure: string) => {
  const yearsAtCompany = Number(tenure) / 12;

  return t('year', {count: yearsAtCompany});
};

type EmployeeExtended = EmployeeBirthday & EmployeeAnniversary;

export const renderPeople = (
  people: EmployeeExtended[],
  showMore: boolean,
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>,
  profileInfoKey: keyof EmployeeExtended,
  transformProfileInfo: (info: string) => string,
  source: string = 'people'
) => {
  const visiblePeople = showMore ? people : people.slice(0, MAX_PEOPLE);

  return (
    <div>
      <div className={styles.profileList}>
        {visiblePeople.map((person: EmployeeExtended) => {
          const rawProfileInfo: string = person[profileInfoKey] as string;
          const profileInfo = rawProfileInfo ? transformProfileInfo(rawProfileInfo) : '';

          return (
            <ProfileCard
              key={person.user_id}
              source={source}
              profile_username={person.ldap}
              profile_photo_url={person.profile_photo_url}
              display_name={person.name}
              profile_info={profileInfo}
            />
          );
        })}
      </div>
      {!showMore && people.length > MAX_PEOPLE && (
        <Button
          className={styles.showMoreButton}
          variant="transparent"
          size="small"
          onClick={() => setShowMore(true)}
        >
          +{people.length - MAX_PEOPLE} {t('more')}
        </Button>
      )}
    </div>
  );
};

const PeopleView = () => {
  const identity = (info: string) => info;
  const [showMoreBirthdays, setShowMoreBirthdays] = useState(false);
  const [showMoreDroplets, setShowMoreDroplets] = useState(false);
  const [showMoreAnniversaries, setShowMoreAnniversaries] = useState(false);
  const [showMoreInterns, setShowMoreInterns] = useState(false);
  const {birthdays, droplets, anniversaries, interns} = usePeople();

  return (
    <div className={styles.peopleContainer}>
      {birthdays.length > 0 && (
        <>
          <Text size="xlarge" className={styles.peopleTitle} isBold>
            {t('birthdays')}
          </Text>
          {renderPeople(birthdays, showMoreBirthdays, setShowMoreBirthdays, 'birthday', identity)}
        </>
      )}
      {droplets.length > 0 && (
        <>
          <Text size="xlarge" className={styles.peopleTitle} isBold>
            {t('droplets')}
          </Text>
          {renderPeople(
            droplets.sort(sortEmployees),
            showMoreDroplets,
            setShowMoreDroplets,
            'role',
            identity
          )}
        </>
      )}
      {anniversaries.length > 0 && (
        <>
          <Text size="xlarge" className={styles.peopleTitle} isBold>
            {t('anniversaries')}
          </Text>
          {renderPeople(
            anniversaries,
            showMoreAnniversaries,
            setShowMoreAnniversaries,
            'tenure',
            getYearsAtCompany
          )}
        </>
      )}
      {interns.length > 0 && (
        <>
          <Text size="xlarge" className={styles.peopleTitle} isBold>
            {t('interns')}
          </Text>
          {renderPeople(
            interns.sort(sortEmployees),
            showMoreInterns,
            setShowMoreInterns,
            'role',
            identity
          )}
        </>
      )}
    </div>
  );
};

export const People = () => {
  const setShowSearch = useSetAtom(showSearchAtom);
  useDocumentTitle(t('people'));

  return (
    <Layout.Container size="large">
      <div
        className={styles.peopleSearchContainer}
        onKeyDown={() => setShowSearch('people')}
        onClick={() => setShowSearch('people')}
      >
        <FormRow>
          <TextInput
            placeholder={t('search_dropboxers').toString()}
            size="large"
            autoFocus
            withLeftAccessory={
              <UIIcon size="large" src={SearchLine} className={atoms({color: 'Text Subtle'})} />
            }
          />
        </FormRow>
      </div>
      <Suspense fallback={null}>
        <PeopleView />
      </Suspense>
    </Layout.Container>
  );
};
