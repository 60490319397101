import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Goal, GoalRead} from 'client';
import {useGoals} from 'components/goals/hooks';
import {isPreviousQuarter} from 'components/shared/TimeAgo';
import {useEmployees} from 'hooks/useEmployee';
import {useAtomValue} from 'jotai';
import {useEffect} from 'react';
import {
  ConfidentialGoalsTable,
  EmptyGoalsforPreviousQuarterTable,
  EmptyMyGoalsTable,
  EmptyOthersGoalsTable,
  NoFilterTable,
} from 'views/goals_v2/EmptyGoalsTable';
import {GoalsTable} from 'views/goals_v2/GoalsTable';
import {GoalsHeaderData, GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {
  filterTable,
  getExpandedTableProps,
  getFilterData,
  groupGoalsByLdap,
  hasTooManyGoals,
  shouldShowMultiGoalsTable,
} from 'views/goals_v2/utils';

const isGoalOwner = (ldaps: string[], goal: Goal | GoalRead) => {
  const goalOnwerLdap = goal.users?.[0]?.email.split('@')[0];
  return goalOnwerLdap && ldaps.includes(goalOnwerLdap);
};

const isContributor = (ldaps: string[], goal: Goal | GoalRead) => {
  return goal.key_results?.some((keyResult) => {
    const contributors = keyResult.contributors?.map(({ldap}) => ldap) ?? [];
    return contributors.some((contributor) => ldaps.includes(contributor));
  });
};

const excludeUnownedGoals = (goals: Goal[], ldaps: string[]) => {
  const ownedGoals = goals.filter((goal) => isGoalOwner(ldaps, goal) || isContributor(ldaps, goal));
  return ownedGoals.map((goal) => {
    return {
      ...goal,
      children: [], // exclude children since we only want to show top level goals
    };
  });
};

/*
 * Table that loads employee goals (based on `ldap`)
 */
export const EmployeeGoalsTableComponent = ({
  index,
  keyPrefix,
  ldap,
  filter,
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  columnConfigs,
  onColumnDrag,
  isGoalsV2DrawerOpen,
  onRowClick,
  onContributorsClick,
  onContinueGoalClick,
  hasScrolledToRow,
  setScrolledToRow,
  selectedGoalData,
  showActionMenu,
  showHeader,
  showUpdates,
  disableShowTitle,
  disableExpandGoalActionMenu,
  disableTooManyGoalsEmptyState = false,
  shouldExcludeUnownedGoals = false,
  containerProps,
  rowProps,
  emptyStateContainerProps,
  emptyStateSubtitleStyleProps,
  goalsHeaderData,
  setGoalsHeaderData,
  handlerAddFilterButtonClick,
}: GoalsTablePropsWithoutData & {
  index: number;
  keyPrefix: string;
  ldap: string;
  disableShowTitle?: boolean;
  disableTooManyGoalsEmptyState?: boolean;
  shouldExcludeUnownedGoals?: boolean;
  emptyStateContainerProps?: {[key: string]: any};
  emptyStateSubtitleStyleProps?: {[key: string]: any};
}) => {
  const user = useAtomValue(pulseUserAtom);
  const {delegatedBy, employee, reportingLine} = useAtomValue(loggedInEmployeeAtom);
  const {managerLdap} = getFilterData(user, reportingLine);

  const ldaps = [ldap];
  const {isFetched, goals: employeeGoals, hasAllPrivateGoals} = useGoals({ldaps, timeframe});
  const goals = shouldExcludeUnownedGoals
    ? excludeUnownedGoals(employeeGoals, ldaps)
    : employeeGoals;
  const groupedGoalsByEmployee = groupGoalsByLdap(goals, ldaps, false);
  const groupedGoals = groupedGoalsByEmployee[ldap] ?? [];
  const filteredGoals = filter ? groupedGoals.filter(filterTable(filter)) : groupedGoals;

  const employees = useEmployees({ldaps});
  const nameTitle = employees.find((e) => e.ldap === ldap)?.name;
  const isSelf = ldap === employee.ldap;
  const isManager = ldap === managerLdap;

  const showMultiGoalsTable = filter ? shouldShowMultiGoalsTable(filter) : false;

  const goalsHeaderDataString = JSON.stringify(goalsHeaderData);
  const filterString = JSON.stringify(filter);
  const employeeGoalsString = JSON.stringify(employeeGoals);
  useEffect(() => {
    // After goals are fetched for ldap, store goals count for header
    if (isFetched && setGoalsHeaderData) {
      const goalsCountMap = goalsHeaderData?.goalsCountMap ?? {};
      const filteredGoalsCountMap = goalsHeaderData?.filteredGoalsCountMap ?? {};
      const teamsMap = goalsHeaderData?.teamsMap ?? {};
      goalsCountMap[ldap] = groupedGoals.length;
      filteredGoalsCountMap[ldap] = filteredGoals.length;
      const newGoalsHeaderData: GoalsHeaderData = {
        ...goalsHeaderData,
        goalsCountMap,
        filteredGoalsCountMap,
        teamsMap,
      };
      setGoalsHeaderData(newGoalsHeaderData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetched,
    goalsHeaderDataString,
    setGoalsHeaderData,
    filterString,
    timeframe,
    employeeGoalsString,
  ]);

  if (
    !disableTooManyGoalsEmptyState &&
    goalsHeaderData &&
    handlerAddFilterButtonClick &&
    hasTooManyGoals(goalsHeaderData, false, filter)
  ) {
    return index === 0 ? <NoFilterTable onButtonClick={handlerAddFilterButtonClick} /> : null;
  }

  if (!showMultiGoalsTable) {
    if (hasAllPrivateGoals) {
      return (
        <ConfidentialGoalsTable
          containerProps={emptyStateContainerProps}
          subtitleStyleProps={emptyStateSubtitleStyleProps}
        />
      );
    }

    if (!goals.length) {
      if (isPreviousQuarter(timeframe)) {
        return (
          <EmptyGoalsforPreviousQuarterTable
            containerProps={emptyStateContainerProps}
            subtitleStyleProps={emptyStateSubtitleStyleProps}
          />
        );
      }

      const delegateEmployee = delegatedBy?.find((delegate) => ldaps.includes(delegate.ldap));
      return isSelf || !!delegateEmployee ? (
        <EmptyMyGoalsTable
          timeframe={timeframe}
          containerProps={emptyStateContainerProps}
          subtitleStyleProps={emptyStateSubtitleStyleProps}
          delegateId={delegateEmployee?.user_id}
        />
      ) : (
        <EmptyOthersGoalsTable
          containerProps={emptyStateContainerProps}
          subtitleStyleProps={emptyStateSubtitleStyleProps}
        />
      );
    }
  }

  return (
    <GoalsTable
      keyPrefix={keyPrefix}
      type={isSelf ? 'self' : 'other'}
      withTitle={
        disableShowTitle
          ? undefined
          : showMultiGoalsTable
          ? nameTitle
          : isSelf || isManager
          ? undefined
          : nameTitle
      }
      data={filteredGoals}
      ownerLdap={ldap}
      filter={filter}
      timeframe={timeframe}
      nextTimeframe={nextTimeframe}
      setNextTimeframe={setNextTimeframe}
      columnConfigs={columnConfigs}
      onColumnDrag={onColumnDrag}
      isGoalsV2DrawerOpen={isGoalsV2DrawerOpen}
      onRowClick={onRowClick}
      onContributorsClick={onContributorsClick}
      onContinueGoalClick={onContinueGoalClick}
      hasScrolledToRow={hasScrolledToRow}
      setScrolledToRow={setScrolledToRow}
      selectedGoalData={selectedGoalData}
      showActionMenu={showActionMenu}
      showCreate={isSelf}
      showHeader={showHeader}
      showUpdates={showUpdates}
      disableExpandGoalActionMenu={disableExpandGoalActionMenu}
      containerProps={containerProps}
      rowProps={rowProps}
      {...getExpandedTableProps(false)}
    />
  );
};
