import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {atoms, Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {MoreHorizontalLine, SearchLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {showSearchAtom} from 'atoms/search';
import {recentSearchAtom, RouteToIcon} from 'components/search/GlobalSearch';
import {t} from 'i18next';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

export const SearchSection = () => {
  const setShowSearch = useSetAtom(showSearchAtom);
  const {employee, reportingLine, employeeTeams} = useAtomValue(loggedInEmployeeAtom);

  const [recentSearches, setRecentSearches] = useAtom(recentSearchAtom);

  useEffect(() => {
    if (!employee.name) {
      return;
    }
    if (!recentSearches.length) {
      const manager = reportingLine?.[1];
      const defaultSearches = [];
      if (manager) {
        defaultSearches.push({
          label: manager.name,
          route: 'people',
          path: manager.ldap,
        });
      }

      employeeTeams.map((team) => {
        defaultSearches.push({
          label: team.name,
          route: 'teams',
          path: team.slug,
        });
      });

      setRecentSearches(defaultSearches);
    }
  }, [employee, employeeTeams, recentSearches, reportingLine, setRecentSearches]);

  return (
    <>
      <Box
        onKeyDown={({key, metaKey, ctrlKey}) => {
          if (
            ctrlKey ||
            metaKey ||
            key.startsWith('Arrow') ||
            ['Tab', 'Alt', 'Escape'].includes(key)
          )
            return;

          setShowSearch('home');
        }}
        onClick={() => setShowSearch('home')}
        style={{marginBottom: -8}}
      >
        <FormRow>
          <TextInput
            placeholder={t('search').toString()}
            size="large"
            autoFocus
            withLeftAccessory={
              <UIIcon size="large" src={SearchLine} className={atoms({color: 'Text Subtle'})} />
            }
          />
        </FormRow>
      </Box>

      <ChipContainer />
    </>
  );
};

const maxLabelLength = 35;

const ChipContainer = () => {
  const navigate = useNavigate();
  const recentSearches = useAtomValue(recentSearchAtom);
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleChips, setVisibleChips] = useState<typeof recentSearches>([]);
  const [overflowChips, setOverflowChips] = useState<typeof recentSearches>([]);

  // Create a temporary chip to calcualte the widths
  const calculateChipWidths = useCallback(() => {
    const chipWidths: number[] = [];
    const tempContainer = document.createElement('div');
    tempContainer.style.visibility = 'hidden';
    tempContainer.style.position = 'absolute';
    document.body.appendChild(tempContainer);

    recentSearches.forEach(({label}) => {
      const chipElement = document.createElement('div');
      chipElement.style.display = 'inline-block';
      chipElement.style.padding = '0 12px';
      chipElement.innerText = label;

      if (label.length > maxLabelLength) {
        chipElement.innerText = `${label.slice(0, maxLabelLength)}...`;
      }

      tempContainer.appendChild(chipElement);
      const width = chipElement.offsetWidth + 24;
      chipWidths.push(width);
    });

    document.body.removeChild(tempContainer);

    return chipWidths;
  }, [recentSearches]);

  const handleOverflow = useCallback(() => {
    const containerWidth = containerRef.current?.parentElement?.offsetWidth || 0;
    let totalWidth = 0;
    const visible: typeof recentSearches = [];
    const overflow: typeof recentSearches = [];
    const chipWidths = calculateChipWidths();

    chipWidths.forEach((chipWidth, index) => {
      totalWidth += chipWidth;
      if (totalWidth > containerWidth) {
        overflow.push(recentSearches[index]);
      } else {
        visible.push(recentSearches[index]);
      }
    });

    setVisibleChips(visible);
    setOverflowChips(overflow);
  }, [calculateChipWidths, recentSearches]);

  useEffect(() => {
    if (recentSearches.length > 0) {
      setVisibleChips(recentSearches);
      handleOverflow();
    }
  }, [handleOverflow, recentSearches]);

  useEffect(() => {
    handleOverflow();
    window.addEventListener('resize', handleOverflow);
    return () => {
      window.removeEventListener('resize', handleOverflow);
    };
  }, [handleOverflow]);

  const handleChipClick = (route: string, path: string) => {
    navigate(`/${route}/${path}`, {state: {source: 'home-chip'}});
    analyticsLogger().logEvent('HOME_RECENT_SEARCH', {route});
  };

  return (
    <Split gap="8" paddingTop="8">
      <div ref={containerRef}>
        {visibleChips.length === 0 ? (
          <Box style={{height: 24}} />
        ) : (
          visibleChips.map(({label, path, route}) => {
            return (
              <Split.Item id={`chip-${label}`} key={`${route}-${path}`}>
                <Chip size="small" onClick={() => handleChipClick(route, path)}>
                  <Chip.IconAccessory>
                    <UIIcon src={RouteToIcon[route as keyof typeof RouteToIcon]} />
                  </Chip.IconAccessory>
                  <Chip.Content>
                    {label.length > maxLabelLength ? `${label.slice(0, maxLabelLength)}...` : label}
                  </Chip.Content>
                </Chip>
              </Split.Item>
            );
          })
        )}

        {overflowChips.length > 0 && (
          <Split.Item>
            <Menu.Wrapper onSelection={({route, path}) => handleChipClick(route, path)}>
              {({getContentProps, getTriggerProps}) => (
                <>
                  <IconButton
                    {...getTriggerProps()}
                    variant="opacity"
                    shape="circular"
                    size="small"
                  >
                    <Box as={UIIcon} src={MoreHorizontalLine} color="Text Subtle" />
                  </IconButton>
                  <Menu.Content {...getContentProps()}>
                    <Menu.Segment>
                      {overflowChips.map(({label, route, path}) => (
                        <Menu.SelectItem
                          preferComposition
                          key={`${route}-${path}`}
                          value={{route, path}}
                        >
                          <Menu.RowAccessory>
                            <UIIcon src={RouteToIcon[route as keyof typeof RouteToIcon]} />
                          </Menu.RowAccessory>
                          <Menu.RowContent>
                            <Menu.RowTitle>
                              {label.length > maxLabelLength
                                ? `${label.slice(0, maxLabelLength)}...`
                                : label}
                            </Menu.RowTitle>
                          </Menu.RowContent>
                        </Menu.SelectItem>
                      ))}
                    </Menu.Segment>
                  </Menu.Content>
                </>
              )}
            </Menu.Wrapper>
          </Split.Item>
        )}
      </div>
    </Split>
  );
};
