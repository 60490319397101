import {Box} from '@dropbox/dig-foundations';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {UpdatePost} from 'components/DSYS/UpdatePost';
import {useProjectUpdatesSuspense} from 'components/projects/hooks';
import {FormattedString} from 'components/shared/table/UpdateCell';
import {t} from 'i18next';

export const ProjectUpdates = ({id}: {id: number}) => {
  const {updates} = useProjectUpdatesSuspense({id});

  if (!updates) {
    return null;
  }

  return (
    <Box paddingY="16">
      <Box as={Eyebrow} id="updates" marginY="8">
        {t('projects_update_title')}
      </Box>
      <UpdatePost hideIcon featured ldap={updates[0].employee.ldap} update={updates[0]}>
        {/* <RichTextArea value={updates[0].comment} /> */}
        <FormattedString comment={updates[0].comment} />
      </UpdatePost>
      {updates.length > 1 && (
        <Box marginTop="24">
          <Box as={Eyebrow} marginY="8">
            {t('projects_update_previous_title')}
          </Box>
        </Box>
      )}
      {updates.slice(1).map((update, index) => (
        <UpdatePost hideIcon key={index} ldap={update.employee.ldap} update={update}>
          {/* <RichTextArea value={update.comment} /> */}
          <FormattedString comment={update.comment} />
        </UpdatePost>
      ))}
    </Box>
  );
};
