import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, InfoLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';

import styles from './NewGoalButtons.module.css';

export const NewGoalButtons = ({
  canSaveGoal,
  canCompleteGoal,
  hasKeyResults,
  isLoading,
  isSavingDraft,
  isGoalSaved,
  handleCancelClick,
  handleContinueClick,
  handleCompleteGoalClick,
  handleSaveAsDraftClick,
}: {
  canSaveGoal: boolean;
  canCompleteGoal: boolean;
  hasKeyResults: boolean;
  isLoading: boolean;
  isSavingDraft: boolean;
  isGoalSaved: boolean;
  handleContinueClick: () => void;
  handleCancelClick: () => void;
  handleCompleteGoalClick: () => void;
  handleSaveAsDraftClick: () => void;
}) => {
  const [isContinueClicked, setIsContinueClicked] = useState<boolean>(false);

  const onContinueClick = () => {
    handleContinueClick();
    setIsContinueClicked(true);
  };

  return (
    <div className={styles.keyResultActionContainer}>
      <div className={styles.keyResultActionItemContainer}>
        {isContinueClicked && isGoalSaved ? (
          <Tooltip title={t('save_draft_tooltip')}>
            <Button
              variant="opacity"
              size="medium"
              fullWidth={false}
              className={styles.cancelButton}
              disabled={!canCompleteGoal}
              onClick={handleSaveAsDraftClick}
              isLoading={isSavingDraft}
            >
              {t('save_draft')}
            </Button>
          </Tooltip>
        ) : (
          <Button
            variant="opacity"
            size="medium"
            fullWidth={false}
            className={styles.cancelButton}
            onClick={handleCancelClick}
            isLoading={false}
          >
            {t('cancel')}
          </Button>
        )}
        <Button
          variant="primary"
          size="medium"
          fullWidth={false}
          className={styles.addKeyResultButton}
          disabled={hasKeyResults ? !canCompleteGoal : !canSaveGoal}
          onClick={hasKeyResults ? handleCompleteGoalClick : onContinueClick}
          isLoading={isLoading}
        >
          {hasKeyResults ? t('publish') : t('save_goal_continue')}
        </Button>
      </div>
    </div>
  );
};

export const CreatePersonalGoalRow = ({
  handleSkipClick,
}: {
  offset: number;
  handleSkipClick: () => void;
}) => {
  return (
    <div className={styles.createGoalContainer}>
      <div className={styles.createGoalWrapper} onMouseDown={handleSkipClick}>
        <IconButton variant="outline" size="large">
          <UIIcon src={AddLine} />
        </IconButton>
        <div className={styles.createGoalTexts}>
          <Text size="small" isBold>
            {t('create_personal_goal')}
          </Text>
          <Text color="faint" size="small">
            {t('carve_your_path')}
          </Text>
        </div>
      </div>
    </div>
  );
};

export const ButtonsFooter = ({
  onSaveDraftClick,
  onPublishClick,
  isLoading,
  isDisabled,
  isGoalCustomPrivate = false,
}: {
  isLoading: boolean;
  isDisabled: boolean;
  onSaveDraftClick: () => void;
  onPublishClick: () => void;
  isGoalCustomPrivate?: boolean;
}) => {
  const loggedInEmployee = useAtomValue(loggedInEmployeeAtom);
  const isManager = loggedInEmployee.directReports?.length > 0;
  const [isDraftClicked, setIsDraftClicked] = useState<boolean>(false);

  const handleSaveDraftClick = () => {
    setIsDraftClicked(true);
    onSaveDraftClick();
  };

  const handlePublishClick = () => {
    setIsDraftClicked(false);
    onPublishClick();
  };

  return (
    <Box
      style={{
        borderTop: '1px solid var(--dig-color__border__subtle)',
        paddingTop: '20px',
        paddingBottom: '24px',
        gap: '30px',
      }}
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      <Box style={{paddingLeft: '12px', paddingRight: '48px'}}>
        <LabelGroup
          withText={
            <Box paddingTop="2" style={{marginLeft: '-4px'}}>
              <Text size="small" color="faint" variant="label">
                {isGoalCustomPrivate
                  ? t('drafts_custom_audience')
                  : isManager
                  ? t('drafts_info_manager')
                  : t('drafts_info_ic')}
              </Text>
            </Box>
          }
          withLeftAccessory={<Box as={UIIcon} src={InfoLine} color="Border Base" />}
          align="top"
          direction="vertical"
        />
      </Box>
      <Box style={{gap: '8px'}} display="flex">
        <Tooltip title={t('save_draft_tooltip')}>
          <Button
            variant="opacity"
            onClick={handleSaveDraftClick}
            isLoading={isLoading && isDraftClicked}
            disabled={isDisabled}
          >
            {t('save_draft')}
          </Button>
        </Tooltip>
        <Button
          data-testid="goal-edit__publish-button"
          variant="primary"
          onClick={handlePublishClick}
          isLoading={isLoading && !isDraftClicked}
          disabled={isDisabled}
        >
          {t('publish')}
        </Button>
      </Box>
    </Box>
  );
};
