import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CalendarLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {MegaphoneMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {HomeService} from 'client';
import {EmptyState} from 'components/DSYS/EmptyState';
import {Link} from 'components/DSYS/Link';
import {format, parseISO} from 'date-fns';
import {t} from 'i18next';
import {getService} from 'utilities';

import {Section} from './Section';

const formatLeaveDate = (dateUnformatted: string) => {
  const parsedDate = parseISO(dateUnformatted);

  return {date: format(parsedDate, 'MMM d'), time: format(parsedDate, 'h:mm a')};
};

const EventItem = ({event}: {event: any}) => {
  const start = formatLeaveDate(event.start.date ?? event.start.dateTime);
  const end = formatLeaveDate(event.end.date ?? event.end.dateTime);

  let startDate = `${start.date} ${start.time}`;
  let endDate = end.time;
  if (event.end.date) {
    startDate = start.date;
    endDate = end.date;
  }

  const handleEventClick = () =>
    analyticsLogger().logEvent('HOME_CALENDAR_CLICKED', {type: 'event'});

  return (
    <Box
      key={event.id}
      paddingY="8"
      paddingX="12"
      borderRadius="Medium"
      cursor="pointer"
      {...withShade({duration: 'None', style: {marginLeft: -8}})}
    >
      <Link to={event.htmlLink} hasNoUnderline onClick={handleEventClick}>
        <LabelGroup
          withText={<Text isBold>{event.summary}</Text>}
          withSubtext={
            <Text size="small" color="faint">
              {startDate === endDate ? startDate : `${startDate} - ${endDate}`}
            </Text>
          }
        />
      </Link>
    </Box>
  );
};

export const CalendarSection = () => {
  const {data, isLoading} = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['home', 'calendar'],
    queryFn: getService(HomeService).calendarApiV1HomeCalendarGet,
  });

  const handleEventClick = () =>
    analyticsLogger().logEvent('HOME_CALENDAR_CLICKED', {type: 'header'});

  return (
    <Section
      title={
        <Link
          hasNoUnderline
          to="https://calendar.google.com/calendar/u/0/embed?src=c_1l2ip0fso394omg6ikk7coti14@group.calendar.google.com"
          onClick={handleEventClick}
        >
          {t('home_calendar')}
        </Link>
      }
      withAccessoryStart={<UIIcon src={CalendarLine} />}
      isLoading={isLoading}
      loadingSkeleton={
        <Stack>
          {Array.from({length: 3}).map((_, index) => (
            <Box
              key={index}
              paddingY="8"
              paddingX="12"
              borderRadius="Medium"
              {...withShade({duration: 'None', style: {marginLeft: -8}})}
            >
              <LabelGroup
                size="large"
                withText={<Box as={Skeleton.Text} style={{width: 240}} />}
                withSubtext={<Skeleton.Text size="small" width={90} />}
              />
            </Box>
          ))}
        </Stack>
      }
      content={
        data ? (
          <Stack>
            {data
              .sort((a: any, b: any) => {
                const aHasDateTime = !!a.start.dateTime;
                const bHasDateTime = !!b.start.dateTime;

                const aIsSameDay = a.start.date && a.end.date && a.start.date === a.end.date;
                const bIsSameDay = b.start.date && b.end.date && b.start.date === b.end.date;

                // If a has dateTime and b doesn't, a comes first
                if (aHasDateTime && !bHasDateTime) {
                  return -1;
                }
                // If b has dateTime and a doesn't, b comes first
                else if (!aHasDateTime && bHasDateTime) {
                  return 1;
                }
                // If a is same day and b isn't, a comes first
                else if (aIsSameDay && !bIsSameDay) {
                  return -1;
                }
                // If b is same day and a isn't, b comes first
                else if (!aIsSameDay && bIsSameDay) {
                  return 1;
                }
                // If both have the same criteria, keep the original order
                else {
                  return 0;
                }
              })
              .map((post: any) => (
                <EventItem key={post.id} event={post} />
              ))}
          </Stack>
        ) : (
          <EmptyState
            title={t('home_empty_calendar_title')}
            body={t('home_empty_calendar_body')}
            image={<MegaphoneMini width="64" altText={t('home_empty_calendar_title')} />}
            hideBorder
          />
        )
      }
    />
  );
};
