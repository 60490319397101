import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {restrictToFirstScrollableAncestor} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {
  derivedShowAIGoalAssistantDrawerAtom,
  newGoalAtom,
  showAIKeyResultAssistantAtom,
  showNewBannerAtom,
  showNewDraftGoalBannerAtom,
} from 'atoms/goals';
import {snackbarAtom} from 'atoms/snackbar';
import {Employee, Goal, GoalCreate, GoalData, KeyResult, KeyResultCreate, Team} from 'client';
import {useGoalService, useKeyResultService} from 'components/goals/hooks';
import {SkeletonEditGoal} from 'components/shared/SkeletonGoal';
import {DEFAULT_TIMEFRAME, ROUTE_PATHS} from 'constant';
import {reportAndLogError} from 'helpers/logging';
import {isEmployee, isTeam} from 'hooks/useEmployee';
import {useProfile} from 'hooks/useProfile';
import {t} from 'i18next';
import {useAtom, useSetAtom} from 'jotai';
import {RESET} from 'jotai/utils';
import {useEffect, useRef, useState} from 'react';
import React from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {EditGoalDetailsDrawer} from 'views/goals_v2/Drawers/EditGoalDetailsDrawer';
import {EditGoalPrivacyDrawer} from 'views/goals_v2/Drawers/EditGoalPrivacyDrawer';
import {ParentGoalSearchDrawer} from 'views/goals_v2/Drawers/ParentGoalSearchDrawer';
import {shouldCloseAndResetDrawers} from 'views/goals_v2/Drawers/utils';
import {getBrowsePath} from 'views/goals_v2/utils';

import {GoalDetailsSection} from './GoalDetailsSection';
import {GoalEditSection, GoalEditSectionHeader, KeyResultEditSection} from './GoalEditSection';
import {ButtonsFooter} from './NewGoalButtons';
import styles from './NewGoalContent.module.css';
import {ParentGoalSection} from './ParentGoalSection';
//import {StrategyConnectSearch} from './StrategyConnectSearch';

const initialGoalCreate: GoalCreate = {
  title: '',
  timeframe: DEFAULT_TIMEFRAME,
  private: false,
  strategy_ids: '',
};

const getInitialKeyResults = (): StagedKeyResult[] => {
  // Create 3 key results object initially
  const newKeyResults = [];
  for (let i = 0; i < 3; i++) {
    newKeyResults.push({
      id: i + 1,
      title: '',
      sort: i,
      created_at: new Date().toISOString(),
    });
  }
  return newKeyResults;
};

type StagedKeyResult = KeyResultCreate & {id: number};

interface NewGoalContentProps {
  delegateId?: string;
  goalCreated: boolean;
  setGoalCreated: (created: boolean) => void;
}

export const NewGoalContent = React.forwardRef<HTMLDivElement, NewGoalContentProps>(
  ({delegateId, goalCreated}, ref) => {
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const bottomRef = useRef<HTMLDivElement>(null);
    const [goal, setGoal] = useState<GoalCreate>({
      ...initialGoalCreate,
      timeframe: searchParams.get('t') ?? location.state?.timeframe ?? DEFAULT_TIMEFRAME,
    });

    const [keyResults, setKeyResults] = useState<StagedKeyResult[]>(getInitialKeyResults());
    const setShowAIGoalAssistantDrawer = useSetAtom(derivedShowAIGoalAssistantDrawerAtom);
    const setShowAIKRAssistantAtom = useSetAtom(showAIKeyResultAssistantAtom);

    const setSnackbarMessage = useSetAtom(snackbarAtom);
    const [, setNewGoal] = useAtom(newGoalAtom);
    const setShowNewBanner = useSetAtom(showNewBannerAtom);
    const setShowNewDraftBanner = useSetAtom(showNewDraftGoalBannerAtom);
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    const [showEditGoalDetailDrawer, setShowEditGoalDetailDrawer] = useState(false);
    const [showCustomPrivacyDrawer, setShowCustomPrivacyDrawer] = useState(false);
    const [showParentGoalSearchDrawer, setShowParentGoalSearchDrawer] = useState(false);
    const [parentGoal, setParentGoal] = useState<GoalData | undefined>(
      location.state?.selectedParentGoal
    );
    const [parentKeyResult, setParentKeyResult] = useState<KeyResult | undefined>(
      location.state?.selectedParentKeyResult
    );

    const parentGoalUserLdap =
      parentGoal?.users?.[0]?.email?.split('@')[0] ?? parentKeyResult?.contributors?.[0]?.ldap;
    const {createGoal} = useGoalService({
      timeframe: goal.timeframe,
      parentGoalUserLdap,
    });
    const {createKeyResult} = useKeyResultService({timeframe: goal.timeframe});

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [saveDraftRequestStatus, setSaveDraftRequestStatus] = useState('idle');
    const canSave = addRequestStatus === 'idle';

    const navigate = useNavigate();

    const {data, isLoading: isDelegateLoading} = useProfile({ldap: delegateId});

    const handleAddKeyResultClick = () => {
      if (keyResults.length === 0) {
        return;
      }
      // Create a new keyResult object
      const newId = Math.max(...keyResults.map((keyResult) => keyResult.id)) + 1;
      const newKeyResult: StagedKeyResult = {
        id: newId,
        title: '',
        sort: keyResults.length,
        created_at: new Date().toISOString(),
      };
      setKeyResults((prevKeyResults) => [...prevKeyResults, newKeyResult]);

      setTimeout(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, 100);
    };

    const navigateToGoalV2 = (source: string, previousSavedGoal: Goal) => {
      analyticsLogger().logEvent('GOAL_CREATED', {
        type: source,
        aligned: Boolean(parentGoal || parentKeyResult),
        krs: keyResults.filter(({title}) => title.length).length,
        contributors: keyResults.reduce(
          (acc, {contributors}) => acc + (contributors?.length ?? 0),
          0
        ),
      });
      if (previousSavedGoal) {
        setShowNewBanner((prev) => ({...prev, [previousSavedGoal.id]: true}));
        setShowNewDraftBanner((prev) => ({...prev, [previousSavedGoal.id]: true}));
        const browsePath = getBrowsePath({goalId: previousSavedGoal.id});
        navigate(browsePath, {state: {source}});
      } else {
        navigate('/goals', {state: {source}});
      }
    };

    const saveKeyResultV2 = async (isDraft: boolean, previousSavedGoal: Goal) => {
      if (keyResults.length > 0) {
        if (canSave && previousSavedGoal) {
          try {
            if (isDraft) {
              setSaveDraftRequestStatus('pending');
            }
            // filter out the one with empty title
            const keyResultsToSave = keyResults
              .filter((kr) => kr.title !== '')
              .map((entry, idx) => {
                return {...entry, sort: idx};
              });
            // Save key results in parallel with Promise.all
            await Promise.all(
              keyResultsToSave.slice(0, keyResultsToSave.length).map(async (entry) => {
                await createKeyResult({
                  goalId: previousSavedGoal.id,
                  userId: previousSavedGoal.users![0].user_id,
                  data: {
                    ...entry,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                  },
                });
              })
            );
          } catch (error) {
            reportAndLogError(error, 'Error saving key result');
          } finally {
            setSaveDraftRequestStatus('idle');
            setNewGoal(RESET);
          }
        }
      }
    };

    const saveGoal = (isDraft: boolean) => {
      if (canSave) {
        setAddRequestStatus('pending');
        const goalToSave = parentKeyResult
          ? {
              ...goal,
              key_result_parent_id: parentKeyResult.id,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              is_draft: isDraft,
            }
          : {
              ...goal,
              parent_id: parentGoal?.id,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              is_draft: isDraft,
            };

        createGoal({
          data: goalToSave,
          employeeId: delegateId,
        })
          .then(async (result) => {
            await saveKeyResultV2(isDraft, result);
            if (isDraft) {
              navigateToGoalV2('draft-goal', result);
            }
            navigateToGoalV2('new-goal', result);
            setSnackbarMessage({
              text: t('saved'),
              action: {
                text: t('add_another_goal'),
                onClick: () => {
                  navigate(ROUTE_PATHS.GOALS_V2_ONBOARDING);
                },
              },
            });
          })
          .finally(() => {
            setAddRequestStatus('idle');
          });
      }
    };

    const handleOnInputChange = (idx: number, value: string) => {
      if (idx == 0) {
        setGoal((prev) => ({...prev, title: value}));
        return;
      }

      const updatedKeyResults = keyResults.map((entry) => {
        if (entry.id === idx) {
          return {...entry, title: value};
        }
        return entry;
      });

      setKeyResults(updatedKeyResults);
    };

    const handleAddContributor = (idx: number, contributorIds: string[]) => {
      const updatedKeyResults = keyResults.map((entry) => {
        if (entry.id === idx) {
          return {...entry, contributors: contributorIds};
        }
        return entry;
      });
      setKeyResults(updatedKeyResults);
    };

    const handleTimeframeSelection = (timeframe: string) => {
      setGoal((prev) => ({...prev, timeframe}));
    };

    const handleVisibilitySelection = (selected: number) => {
      if (selected == 0) {
        setGoal((prev) => ({...prev, private: false, is_custom_privacy_included: false}));
      }
      if (selected == 1) {
        setGoal((prev) => ({...prev, private: true, is_custom_privacy_included: false}));
      }
      if (selected == 2) {
        setGoal((prev) => ({...prev, private: false, is_custom_privacy_included: true}));
        setShowCustomPrivacyDrawer(true);
      }
    };

    const handleCustomPrivacySaved = (employeesOrTeams: (Employee | Team)[]) => {
      // get only employees from employeesOrTeams list by utilizing isEmployee function
      const employees = employeesOrTeams.filter(isEmployee);
      const teams = employeesOrTeams.filter(isTeam);
      setGoal((prev) => ({...prev, individual_privacies: employees, team_privacies: teams}));
      setShowCustomPrivacyDrawer(false);
    };

    /*
  const handleStrategiesSelection = (strategyIds: string) => {
    setGoal((prev) => ({...prev, strategy_ids: strategyIds}));
  };
  */

    const handleOnDeleteKeyResult = (idx: number) => {
      const updatedKeyResults = keyResults.filter((entry) => entry.id !== idx);
      setKeyResults(updatedKeyResults);
    };

    const disableDeleteKeyResult = (idx: number) => {
      const remainingKeyResults = keyResults.filter((entry) => entry.id !== idx);
      const remainingKeyResultsTitles = remainingKeyResults.map((entry) => entry.title);
      const currentKeyResult = keyResults.find((entry) => entry.id === idx);
      const currentKeyResultTitle = currentKeyResult ? currentKeyResult.title : '';
      // if only one key result left, disable delete
      if (remainingKeyResults.length === 0) {
        return true;
      }
      return (
        currentKeyResultTitle !== '' && remainingKeyResultsTitles.every((title) => title === '')
      );
    };

    function handleDragEnd(event: DragEndEvent) {
      const {active, over} = event;

      if (active.id !== over?.id) {
        setKeyResults((items) => {
          const oldIndex = items.findIndex((entry) => entry.id === active.id);
          const newIndex = items.findIndex((entry) => entry.id === over?.id);

          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }

    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        const header = document.querySelector('.dig-GlobalHeader');
        const drawer = document.querySelector('.dig-Drawer');

        const toolTipOveraly = document.querySelector('.dig-Toggletip-backdrop');
        const toopTipInner = document.querySelector('.dig-Tooltip-inner');

        if (
          (!header || !header.contains(event.target as Node)) &&
          (!drawer || !drawer.contains(event.target as Node)) &&
          (!toolTipOveraly || !toolTipOveraly.contains(event.target as Node)) &&
          (!toopTipInner || !toopTipInner.contains(event.target as Node)) &&
          shouldCloseAndResetDrawers(event.target as HTMLElement)
        ) {
          setShowCustomPrivacyDrawer(false);
          setShowEditGoalDetailDrawer(false);
          setShowParentGoalSearchDrawer(false);
        }
      };

      document.body.addEventListener('click', handleClick);

      return () => {
        document.body.removeEventListener('click', handleClick);
      };
    }, []);

    if (isDelegateLoading) {
      return <SkeletonEditGoal />;
    }

    const renderKeyResults = () => {
      return (
        <div className={styles.keyResultContainer} ref={bottomRef}>
          <GoalEditSectionHeader subtitle={t('key_results')} tooltip={t('key_result_tooltip')} />
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToFirstScrollableAncestor]}
          >
            <SortableContext items={keyResults} strategy={verticalListSortingStrategy}>
              {keyResults.map((keyResultEntry) => {
                return (
                  <Box style={{marginBottom: -24}} key={keyResultEntry.id} width="100%">
                    <FormRow>
                      <KeyResultEditSection
                        source="create"
                        idx={keyResultEntry.id}
                        keyResultCreateValues={keyResultEntry}
                        delegateId={delegateId}
                        handleOnInputChange={handleOnInputChange}
                        handleOnDelete={handleOnDeleteKeyResult}
                        handleAddContributor={handleAddContributor}
                        disableDelete={disableDeleteKeyResult(keyResultEntry.id)}
                        ref={ref}
                        goalCreateValues={goal}
                      />
                    </FormRow>
                  </Box>
                );
              })}
            </SortableContext>
          </DndContext>
          <IconButton
            variant="outline"
            shape="circular"
            size="small"
            onClick={handleAddKeyResultClick}
          >
            <Box as={UIIcon} src={AddLine} color="Border Base" />
          </IconButton>
        </div>
      );
    };

    return (
      <>
        <Box style={{gap: '24px'}} display="flex" flexDirection="column">
          <ParentGoalSection
            isDrawerOpen={showParentGoalSearchDrawer}
            onEditClick={() => {
              setShowParentGoalSearchDrawer(true);
              setShowAIGoalAssistantDrawer(false);
              setShowAIKRAssistantAtom({});
            }}
            onDeleteClick={() => {
              setParentGoal(undefined);
              setParentKeyResult(undefined);
            }}
            parentGoal={parentGoal}
            parentKeyResult={parentKeyResult}
          />
          <Box style={{marginBottom: '-20px'}}>
            <GoalEditSection
              idx={0}
              goalCreated={goalCreated}
              goalCreateValues={goal}
              handleOnInputChange={handleOnInputChange}
            />
          </Box>
          {renderKeyResults()}
          <GoalDetailsSection
            onEditClick={() => {
              setShowEditGoalDetailDrawer(true);
              setShowAIGoalAssistantDrawer(false);
              setShowAIKRAssistantAtom({});
            }}
            goalCreateValues={goal}
            isEditClicked={showEditGoalDetailDrawer}
            delegatesProfilePage={data}
          />
          <EditGoalDetailsDrawer
            isOpen={showEditGoalDetailDrawer}
            onClose={() => setShowEditGoalDetailDrawer(false)}
            goalCreateValues={goal}
            handleTimeframeSelection={handleTimeframeSelection}
            handleVisibilitySelection={handleVisibilitySelection}
            delegateProfilePage={data}
          />
          {showCustomPrivacyDrawer && (
            <EditGoalPrivacyDrawer
              isOpen={showCustomPrivacyDrawer}
              onClose={() => setShowCustomPrivacyDrawer(false)}
              onBack={() => setShowCustomPrivacyDrawer(false)}
              onCustomPrivacySave={handleCustomPrivacySaved}
              delegateId={delegateId}
              contributorLdaps={keyResults.flatMap((entry) => entry.contributors ?? [])}
              goalCreateValues={goal}
              delegateProfilePage={data}
            />
          )}
          <ParentGoalSearchDrawer
            isOpen={showParentGoalSearchDrawer}
            onClose={() => setShowParentGoalSearchDrawer(false)}
            setParentGoal={setParentGoal}
            setParentKeyResult={setParentKeyResult}
            selectedParentGoal={parentGoal}
          />
          <ButtonsFooter
            onSaveDraftClick={() => saveGoal(true)}
            onPublishClick={() => saveGoal(false)}
            isLoading={addRequestStatus != 'idle' || saveDraftRequestStatus != 'idle'}
            isDisabled={
              goal.title.length === 0 ||
              keyResults.length === 0 ||
              !keyResults.some((entry) => entry.title.length !== 0)
            }
            isGoalCustomPrivate={goal.is_custom_privacy_included}
          />
        </Box>
      </>
    );
  }
);

NewGoalContent.displayName = 'NewGoalContent';
