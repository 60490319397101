import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowLeftLine, EditLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {newsPostIdAtom} from 'atoms/news';
import {NewsPost} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {NewsEmptyState} from 'components/news/NewsEmptyState';
import {NewsList} from 'components/news/NewsList';
import {isContentEditor} from 'helpers/utils';
import {useCondensedNews} from 'hooks/isMobile';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtom, useAtomValue} from 'jotai';
import {useEffect} from 'react';
import {useLoaderData, useNavigate, useParams} from 'react-router-dom';

import {NewsPostRight} from './NewsPostRight';
import styles from './NewsView.module.css';

export const NewsView = () => {
  const [latestPosts, newsPost] = useLoaderData() as [NewsPost[], NewsPost | undefined];
  const params = useParams();
  const [loadedNewsPostId, setLoadedNewsPostId] = useAtom(newsPostIdAtom);
  const categoryInUrl = params.identifier && !/^\d+$/.test(params.identifier);
  const isMobile = useCondensedNews();
  const pulseUser = useAtomValue(pulseUserAtom);
  const navigate = useNavigate();
  const isEditorView = pulseUser && isContentEditor(pulseUser.email);

  useDocumentTitle(categoryInUrl ? (params.identifier ?? t('news')) : t('news'));

  useEffect(() => {
    if (params.identifier && /^\d+$/.test(params.identifier)) {
      const id = parseInt(params.identifier, 10);
      setLoadedNewsPostId(id);
    }
  }, [params.identifier, setLoadedNewsPostId]);

  useEffect(() => {
    if (categoryInUrl) {
      analyticsLogger().logEvent('NEWS_VIEWED', {type: 'category'});
    } else if (params.identifier && /^\d+$/.test(params.identifier)) {
      analyticsLogger().logEvent('NEWS_VIEWED', {type: 'post'});
    } else if (!params.identifier) {
      analyticsLogger().logEvent('NEWS_VIEWED', {type: 'home'});
    }
  }, [categoryInUrl, params.identifier]);

  if (!newsPost && latestPosts.length === 0) {
    return <NewsEmptyState isEditorView />;
  }

  const categoryName = latestPosts[0].category.find(
    (category) => category.name.toLowerCase() === params.identifier?.toLowerCase()
  )?.name;

  if (isMobile) {
    return !params.identifier || categoryInUrl ? (
      <Layout.Container>
        <Layout.Nav>
          <div className={styles.newsFeed}>
            <Title
              className={styles.title}
              size={24}
              withAccessoryEnd={
                categoryInUrl && (
                  <Chip
                    aria-label={params.identifier + '- press backspace or delete to remove'}
                    size="small"
                    onDelete={() => {
                      navigate('/news');
                    }}
                  >
                    <Chip.Content>{categoryName}</Chip.Content>
                  </Chip>
                )
              }
            >
              {t('news')}
            </Title>
            <NewsList
              isEditorView={isEditorView}
              viewCategory={categoryInUrl ? categoryName : ''}
              selected={loadedNewsPostId}
            />
          </div>
        </Layout.Nav>
      </Layout.Container>
    ) : (
      <Layout.Container>
        <div className={styles.mobileActions}>
          <ButtonLink
            to="/news"
            variant="borderless"
            withIconStart={<UIIcon src={ArrowLeftLine}></UIIcon>}
          >
            {t('all_news')}
          </ButtonLink>
          {isEditorView && (
            <div className={styles.editorActions}>
              <IconButton
                variant="outline"
                shape="circular"
                onClick={() => {
                  analyticsLogger().logEvent('NEWS_EDIT_POST_CLICKED', {
                    newspost_id: loadedNewsPostId,
                  });
                  navigate('/news/' + loadedNewsPostId + '/edit');
                }}
              >
                <UIIcon src={EditLine} />
              </IconButton>
              <ButtonLink
                onClick={() => analyticsLogger().logEvent('NEWS_ADD_POST_CLICKED')}
                to="/news/new"
                variant="primary"
              >
                {t('new_post')}
              </ButtonLink>
            </div>
          )}
        </div>

        <div className={styles.mobileNewsBody}>
          <NewsPostRight
            id={loadedNewsPostId ?? (newsPost?.id ? newsPost.id : latestPosts[0].id)}
          />
        </div>
      </Layout.Container>
    );
  }

  return (
    <Layout.Container>
      <Layout.Nav>
        <div className={styles.newsFeed}>
          <Title
            className={styles.title}
            size={24}
            withAccessoryEnd={
              categoryInUrl && (
                <Chip
                  aria-label={params.identifier + '- press backspace or delete to remove'}
                  size="small"
                  onDelete={() => {
                    navigate('/news');
                  }}
                >
                  <Chip.Content>{categoryName}</Chip.Content>
                </Chip>
              )
            }
          >
            {t('news')}
          </Title>
          <NewsList
            isEditorView={isEditorView}
            viewCategory={categoryInUrl ? categoryName : ''}
            selected={loadedNewsPostId}
          />
        </div>
      </Layout.Nav>
      <Layout.Body>
        {isEditorView && (
          <div className={styles.actions}>
            <IconButton
              variant="outline"
              shape="circular"
              onClick={() => {
                analyticsLogger().logEvent('NEWS_EDIT_POST_CLICKED', {
                  newspost_id: loadedNewsPostId,
                });
                navigate('/news/' + loadedNewsPostId + '/edit');
              }}
            >
              <UIIcon src={EditLine} />
            </IconButton>
            <ButtonLink
              onClick={() => analyticsLogger().logEvent('NEWS_ADD_POST_CLICKED')}
              to="/news/new"
              variant="primary"
            >
              {t('new_post')}
            </ButtonLink>
          </div>
        )}
        <div className={styles.newsBody}>
          <NewsPostRight
            id={loadedNewsPostId ?? (newsPost?.id ? newsPost.id : latestPosts[0].id)}
          />
        </div>
      </Layout.Body>
    </Layout.Container>
  );
};
