import {Suspense} from 'react';
import {DashboardsView} from 'views/dashboards/DashboardsView';

export const Dashboards = () => {
  return (
    <Suspense fallback={null}>
      <DashboardsView />
    </Suspense>
  );
};
