/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Digest } from '../models/Digest';
import type { ProjectCreate } from '../models/ProjectCreate';
import type { ProjectData } from '../models/ProjectData';
import type { ProjectDetails } from '../models/ProjectDetails';
import type { ProjectEdit } from '../models/ProjectEdit';
import type { ProjectFollowing } from '../models/ProjectFollowing';
import type { ProjectTicket } from '../models/ProjectTicket';
import type { ProjectUpdate } from '../models/ProjectUpdate';
import type { ProjectUpdateCreate } from '../models/ProjectUpdateCreate';
import type { TeamProjectData } from '../models/TeamProjectData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectService {

    /**
     * Create
     * @param requestBody
     * @returns ProjectData Successful Response
     * @throws ApiError
     */
    public static createApiV1ProjectsPost(
        requestBody: ProjectCreate,
    ): CancelablePromise<ProjectData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Tickets
     * @param id
     * @returns ProjectTicket Successful Response
     * @throws ApiError
     */
    public static getTicketsApiV1ProjectsTicketsIdGet(
        id: number,
    ): CancelablePromise<Array<ProjectTicket>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/tickets/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Following
     * @param identifier
     * @returns ProjectFollowing Successful Response
     * @throws ApiError
     */
    public static followingApiV1ProjectsIdentifierFollowingGet(
        identifier: string,
    ): CancelablePromise<ProjectFollowing> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/{identifier}/following',
            path: {
                'identifier': identifier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Follow
     * @param identifier
     * @returns any Successful Response
     * @throws ApiError
     */
    public static followApiV1ProjectsIdentifierFollowPost(
        identifier: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects/{identifier}/follow',
            path: {
                'identifier': identifier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendEmailApiV1ProjectsEmailGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/email',
        });
    }

    /**
     * Unfollow
     * @param identifier
     * @returns any Successful Response
     * @throws ApiError
     */
    public static unfollowApiV1ProjectsIdentifierUnfollowPost(
        identifier: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects/{identifier}/unfollow',
            path: {
                'identifier': identifier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get
     * @param identifier
     * @returns TeamProjectData Successful Response
     * @throws ApiError
     */
    public static getApiV1ProjectsIdentifierTeamGet(
        identifier: string,
    ): CancelablePromise<TeamProjectData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/{identifier}/team',
            path: {
                'identifier': identifier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get
     * @param identifier
     * @returns ProjectData Successful Response
     * @throws ApiError
     */
    public static getApiV1ProjectsIdentifierGet(
        identifier: string,
    ): CancelablePromise<ProjectData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/{identifier}',
            path: {
                'identifier': identifier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get
     * @param identifier
     * @returns ProjectData Successful Response
     * @throws ApiError
     */
    public static getApiV1ProjectsIdentifierQuickGet(
        identifier: string,
    ): CancelablePromise<ProjectData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/{identifier}/quick',
            path: {
                'identifier': identifier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Project Details
     * Get extra details for a project for its project page (contributors, links, etc.)
     * @param projectId
     * @returns ProjectDetails Successful Response
     * @throws ApiError
     */
    public static projectDetailsApiV1ProjectsProjectIdDetailsGet(
        projectId: number,
    ): CancelablePromise<ProjectDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/{project_id}/details',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Project Updates
     * Get goal by ID.
     * @param projectId
     * @param skip
     * @param limit
     * @returns ProjectUpdate Successful Response
     * @throws ApiError
     */
    public static projectUpdatesApiV1ProjectsProjectIdUpdatesGet(
        projectId: number,
        skip?: number,
        limit: number = 100,
    ): CancelablePromise<Array<ProjectUpdate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/{project_id}/updates',
            path: {
                'project_id': projectId,
            },
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit
     * Update a project.
     * @param projectId
     * @param employeeId
     * @param requestBody
     * @returns ProjectData Successful Response
     * @throws ApiError
     */
    public static editApiV1ProjectsProjectIdPut(
        projectId: number,
        employeeId: string,
        requestBody: ProjectEdit,
    ): CancelablePromise<ProjectData> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            query: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * Delete a project.
     * @param projectId
     * @param employeeId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteApiV1ProjectsProjectIdDelete(
        projectId: number,
        employeeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            query: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Projects
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createProjectsApiV1SeedProjectsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/seed/projects',
        });
    }

    /**
     * Get Digest
     * @returns Digest Successful Response
     * @throws ApiError
     */
    public static getDigestApiV1DigestProjectsGet(): CancelablePromise<Digest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/digest/projects',
        });
    }

    /**
     * Create Update
     * Create new update.
     * @param employeeId
     * @param requestBody
     * @returns ProjectUpdate Successful Response
     * @throws ApiError
     */
    public static createUpdateApiV1ProjectsUpdatePost(
        employeeId: string,
        requestBody: ProjectUpdateCreate,
    ): CancelablePromise<ProjectUpdate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/projects/update',
            query: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Asset
     * @param key
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAssetApiV1ProjectsAssetsKeyGet(
        key: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/projects/assets/{key}',
            path: {
                'key': key,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
