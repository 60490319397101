/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_image_api_v1_news_thumbnail_post } from '../models/Body_upload_image_api_v1_news_thumbnail_post';
import type { Body_upload_media_api_v1_news_media_post } from '../models/Body_upload_media_api_v1_news_media_post';
import type { NewsCategoryUpdate } from '../models/NewsCategoryUpdate';
import type { NewsCategoryWithCount } from '../models/NewsCategoryWithCount';
import type { NewsPost } from '../models/NewsPost';
import type { NewsPostContent } from '../models/NewsPostContent';
import type { NewsPostCreate } from '../models/NewsPostCreate';
import type { NewsPostUpdate } from '../models/NewsPostUpdate';
import type { NewsPostWithRead } from '../models/NewsPostWithRead';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NewsService {

    /**
     * Get Posts
     * @param skip
     * @param limit
     * @param category
     * @returns NewsPostWithRead Successful Response
     * @throws ApiError
     */
    public static getPostsApiV1NewsGet(
        skip?: number,
        limit: number = 25,
        category: string = '',
    ): CancelablePromise<Array<NewsPostWithRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/news/',
            query: {
                'skip': skip,
                'limit': limit,
                'category': category,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Post
     * @param requestBody
     * @returns NewsPostContent Successful Response
     * @throws ApiError
     */
    public static createPostApiV1NewsPost(
        requestBody: NewsPostCreate,
    ): CancelablePromise<NewsPostContent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/news/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Seed
     * @returns string Successful Response
     * @throws ApiError
     */
    public static seedApiV1NewsSeedGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/news/seed',
        });
    }

    /**
     * Get Drafts
     * @returns NewsPost Successful Response
     * @throws ApiError
     */
    public static getDraftsApiV1NewsDraftsGet(): CancelablePromise<Array<NewsPost>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/news/drafts',
        });
    }

    /**
     * Get Categories
     * @returns NewsCategoryWithCount Successful Response
     * @throws ApiError
     */
    public static getCategoriesApiV1NewsCategoriesGet(): CancelablePromise<Array<NewsCategoryWithCount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/news/categories',
        });
    }

    /**
     * Edit Categories
     * @param requestBody
     * @returns NewsCategoryWithCount Successful Response
     * @throws ApiError
     */
    public static editCategoriesApiV1NewsCategoriesEditPost(
        requestBody: Array<NewsCategoryUpdate>,
    ): CancelablePromise<Array<NewsCategoryWithCount>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/news/categories/edit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Image
     * @param id
     * @param formData
     * @returns number Successful Response
     * @throws ApiError
     */
    public static uploadImageApiV1NewsThumbnailPost(
        id: number,
        formData: Body_upload_image_api_v1_news_thumbnail_post,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/news/thumbnail',
            query: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Image
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getImageApiV1NewsThumbnailIdGet(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/news/thumbnail/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Image
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteImageApiV1NewsThumbnailIdDelete(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/news/thumbnail/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Media
     * @param asset
     * @param formData
     * @returns string Successful Response
     * @throws ApiError
     */
    public static uploadMediaApiV1NewsMediaPost(
        asset: string,
        formData: Body_upload_media_api_v1_news_media_post,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/news/media',
            query: {
                'asset': asset,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Media
     * @param asset
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getMediaApiV1NewsMediaAssetGet(
        asset: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/news/media/{asset}',
            path: {
                'asset': asset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get By Category
     * @param category
     * @returns NewsPostWithRead Successful Response
     * @throws ApiError
     */
    public static getByCategoryApiV1NewsCategoryCategoryGet(
        category: string,
    ): CancelablePromise<Array<NewsPostWithRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/news/category/{category}',
            path: {
                'category': category,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Viewer
     * @param id
     * @returns number Successful Response
     * @throws ApiError
     */
    public static addViewerApiV1NewsViewsIdPost(
        id: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/news/views/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Post
     * @param id
     * @returns NewsPostContent Successful Response
     * @throws ApiError
     */
    public static getPostApiV1NewsIdGet(
        id: number,
    ): CancelablePromise<NewsPostContent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/news/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Post
     * @param id
     * @param requestBody
     * @returns NewsPostContent Successful Response
     * @throws ApiError
     */
    public static updatePostApiV1NewsIdPost(
        id: number,
        requestBody: NewsPostUpdate,
    ): CancelablePromise<NewsPostContent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/news/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Post
     * @param id
     * @returns NewsPost Successful Response
     * @throws ApiError
     */
    public static deletePostApiV1NewsIdDelete(
        id: number,
    ): CancelablePromise<NewsPost> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/news/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
