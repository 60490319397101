import {useQuery, useSuspenseQuery} from '@tanstack/react-query';
import {Team as TeamClientType, TeamService} from 'client';
import {getService} from 'utilities';

// Return type of `getTeamBySlugApiV1TeamsSlugSlugGet`
export interface TeamMember {
  name: string;
  ldap: string;
  role: string;
}
export interface Team {
  teamId: string;
  name: string;
  slug: string;
  url?: string;
  email?: string;
  description?: string;
  primaryContact?: {
    name: string;
    ldap: string;
  };
  countProjects?: number;
  slack?: string;
  countMembers?: number;
  dbxTeamId?: number;
  members: TeamMember[];
  totalMembers?: number;
  subTeams?: Team[];
  hierarchy?: Team[];
}

export interface TeamsTreeItem {
  teamName: string;
  teamId: string;
  slug: string;
  dbxTeamId?: string;
  subTeams?: TeamsTreeItem[];
}

export const useTeamsTreeSuspense = (): TeamsTreeItem[] => {
  const {data} = useSuspenseQuery({
    queryKey: ['teamTree'],
    queryFn: getService(TeamService).getTeamsApiV1TeamsGet,
  });

  return data;
};

interface TeamCount {
  employees: number;
  teams: number;
}

export const useTeamsCountSuspense = (): TeamCount => {
  const {
    data: {employees, teams},
  } = useSuspenseQuery({
    queryKey: ['teamCount'],
    queryFn: getService(TeamService).getCountApiV1CountGet,
  });

  return {employees, teams};
};

export const useTeamSuspense = ({
  slug,
  includeHierarchy,
  includeSubteamMembers = false,
}: {
  slug: string;
  includeHierarchy?: boolean;
  includeSubteamMembers?: boolean;
}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['team', slug, includeHierarchy, includeSubteamMembers],
    queryFn: () =>
      slug
        ? getService(TeamService).getTeamBySlugApiV1TeamsSlugSlugGet(
            slug,
            includeHierarchy,
            includeSubteamMembers
          )
        : null,
  });

  return data as Team | null;
};

export const useTeam = ({
  slug,
  includeHierarchy = false,
}: {
  slug?: string;
  includeHierarchy?: boolean;
}) => {
  const {data} = useQuery({
    queryKey: ['team', slug, includeHierarchy],
    queryFn: () =>
      getService(TeamService).getTeamBySlugApiV1TeamsSlugSlugGet(slug ?? '', includeHierarchy),
    enabled: !!slug,
  });

  return data as Team;
};

export const useTeamsMapSuspense = ({slugs}: {slugs: string[]}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['teams', slugs],
    queryFn: () =>
      slugs.length
        ? getService(TeamService).getTeamBySlugsApiV1TeamsSlugsSlugsGet(slugs.join(','))
        : [],
  });

  const teamsMap = data.reduce((acc, team) => {
    return {
      ...acc,
      [team.slug ?? '']: team,
    };
  }, {}) as Record<string, TeamClientType>;

  return teamsMap;
};
