/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardService {

    /**
     * Goals Count Endpoint for Dashboards
     * @param teamSlug
     * @param timeframe
     * @param includeMembersCounts
     * @param includeSubteamsCounts
     * @returns any Successful Response
     * @throws ApiError
     */
    public static goalsCountApiV1DashboardsGoalsCountGet(
        teamSlug?: (string | null),
        timeframe?: (string | null),
        includeMembersCounts?: (boolean | null),
        includeSubteamsCounts?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dashboards/goals_count',
            query: {
                'team_slug': teamSlug,
                'timeframe': timeframe,
                'include_members_counts': includeMembersCounts,
                'include_subteams_counts': includeSubteamsCounts,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
