import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {Menu, WrapperRefObject} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {FilterLine, PersonLine} from '@dropbox/dig-icons/assets';
import {isMobileAtom} from 'atoms/layout';
import {useEmployees} from 'hooks/useEmployee';
import {useAtomValue} from 'jotai';
import {LegacyRef, ReactNode, useEffect} from 'react';
import {JoinGoalEmployeeSearch} from 'views/goals_v2/GoalEmployeeSearch';
import {GoalsTableFilter} from 'views/goals_v2/types';

type Filter = {type: keyof GoalsTableFilter; value: string | number | string[]};

const FilterSection = ({
  options,
  section,
}: {
  section: string;
  options: {label?: string | ReactNode; option: Filter}[];
  filter: GoalsTableFilter;
}) => {
  return (
    <Box>
      <Box
        as={Text}
        isBold
        size="small"
        color="Text Subtle"
        paddingY="4"
        paddingX="16"
        display="block"
      >
        {section}
      </Box>

      <Box paddingY="4" paddingX="16">
        {options[0].label}
      </Box>
    </Box>
  );
};

export const FilterChips = ({
  filter,
  setFilter,
}: {
  filter: GoalsTableFilter;
  setFilter: (filter: GoalsTableFilter, shouldNotUpdateUrl?: boolean) => void;
}) => {
  const employees = useEmployees({ldaps: filter.people});

  const getLabelIcon = () => {
    return <UIIcon src={PersonLine} />;
  };

  return (
    <Split alignY="center" paddingTop="4">
      <Split gap="4" alignX="right" style={{flexWrap: 'wrap'}}>
        {Object.entries(filter).map(([type, values]) => {
          if (type === 'people') {
            return (values as string[]).map((value) => {
              if (!value) {
                return null;
              }
              return (
                <Split.Item key={`chip-${type}-${value}`}>
                  <Chip
                    isSelected
                    size="small"
                    onDelete={() => {
                      const toUpdate = [...(filter[type as keyof GoalsTableFilter] as string[])];
                      const index = toUpdate.indexOf(value);
                      toUpdate.splice(index, 1);

                      const newFilter = {...filter, [type]: toUpdate};
                      setFilter(newFilter);
                    }}
                  >
                    <Chip.IconAccessory>{getLabelIcon()}</Chip.IconAccessory>
                    <Chip.Content>
                      {employees.find((employee) => employee.ldap === value)?.name ?? value}
                    </Chip.Content>
                  </Chip>
                </Split.Item>
              );
            });
          }
          return null;
        })}
      </Split>
    </Split>
  );
};

const getFilterTypes = (
  employees: string[] | undefined,
  handleSetEmployees: (employees: string[]) => void,
  delegateLdap?: string
): {[key: string]: {label?: string | ReactNode; option: Filter}[]} => ({
  people: [
    {
      label: (
        <JoinGoalEmployeeSearch
          employees={employees}
          handleEmployeeSelected={handleSetEmployees}
          delegateLdap={delegateLdap}
        />
      ),
      option: {
        type: 'people',
        value: '',
      },
    },
  ],
});

export const JoinTableFilter = ({
  filter,
  setFilter,
  filterButtonRef,
  delegateLdap,
}: {
  filter: GoalsTableFilter;
  setFilter: (filter: GoalsTableFilter, shouldNotUpdateUrl?: boolean) => void;
  filterButtonRef?: LegacyRef<WrapperRefObject>;
  delegateLdap?: string;
}) => {
  const isMobile = useAtomValue(isMobileAtom);

  const handleSetEmployees = (people: string[]) => {
    const newFilter = {...filter, people};
    setFilter(newFilter);
  };

  useEffect(() => {
    if (!filter.people.length) {
      return;
    }
    // create simulated click event to close the menu on the right most side
    setTimeout(() => {
      try {
        const event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        document.dispatchEvent(event);
      } catch (e) {
        // do nothing
      }
    }, 200);
  }, [filter]);

  const filterTypes = getFilterTypes(filter.people, handleSetEmployees, delegateLdap);

  return (
    <Menu.Wrapper closeOnSelection={true} isPortaled={false} ref={filterButtonRef}>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Box as="div" display="flex">
            <Box
              as={IconButton}
              {...getTriggerProps()}
              shape="circular"
              variant="transparent"
              marginLeft="8"
              marginTop="0"
              style={{minWidth: '32px', minHeight: '32px'}}
            >
              <UIIcon src={FilterLine} />
            </Box>
          </Box>
          <Menu.Content {...getContentProps()} placement="bottom">
            <Box
              as="div"
              paddingLeft="4"
              paddingRight="12"
              paddingBottom="16"
              paddingTop="8"
              style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? 'repeat(1fr, 3)' : 'repeat(3, 1fr)',
              }}
            >
              <Box as={Stack} gap="12" style={{flex: 1, width: 300}}>
                <FilterSection section="People" options={filterTypes.people} filter={filter} />
              </Box>
            </Box>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
