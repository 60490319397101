import {useMedia} from './useMedia';

export const useMobile = () => {
  const isMobile = useMedia<boolean>(['(max-width: 860px)'], [true], false);
  return isMobile;
};

export const useCondensedNews = () => {
  return useMedia<boolean>(['(max-width: 1140px)'], [true], false);
};
