import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  BoxViewLine,
  BriefcaseLine,
  GratitudeLine,
  HomeLine,
  LightbulbSpot,
  NewsLine,
  PersonMultipleLine,
  PulseLine,
  TargetLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import {growthbookCacheAtom, isMobileAtom} from 'atoms/layout';
import cx from 'classnames';
import {Link} from 'components/DSYS/Link';
import {ROUTE_PATHS} from 'constant';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useLocation} from 'react-router-dom';

import styles from './LeftNav.module.css';

export const LeftNav = () => {
  const {isDashboardsEnabled, isHomeEnabled, isNewsEnabled, isProjectsEnabled, isPulseEnabled} =
    useAtomValue(growthbookCacheAtom);
  const isMobile = useAtomValue(isMobileAtom);

  const navItems = [
    {
      text: t('home'),
      path: ROUTE_PATHS.HOME,
      icon: HomeLine,
      hidden: !isHomeEnabled,
    },
    {
      text: t('goals'),
      path: ROUTE_PATHS.GOALS,
      icon: TargetLine,
      state: {resetView: true},
    },
    {
      text: t('people'),
      path: ROUTE_PATHS.PEOPLE,
      icon: PersonMultipleLine,
    },
    {
      text: t('teams'),
      path: ROUTE_PATHS.TEAMS,
      icon: TeamLine,
    },
    {
      text: t('news'),
      path: ROUTE_PATHS.NEWS,
      icon: NewsLine,
      hidden: !isNewsEnabled,
    },
    {
      text: t('projects'),
      path: ROUTE_PATHS.PROJECTS,
      icon: BriefcaseLine,
      hidden: !isProjectsEnabled,
    },
    {
      text: t('dashboards'),
      path: ROUTE_PATHS.DASHBOARDS,
      icon: BoxViewLine,
      hidden: !isDashboardsEnabled,
    },
    {
      text: t('pulse'),
      path: ROUTE_PATHS.PULSES,
      icon: PulseLine,
      hidden: !isPulseEnabled,
    },
    {
      text: t('strategy_plural'),
      path: ROUTE_PATHS.STRATEGIES,
      icon: LightbulbSpot,
    },
    {
      text: t('gratitude'),
      path: ROUTE_PATHS.GRATITUDE_BROWSE,
      icon: GratitudeLine,
    },
  ].filter((item) => !item.hidden);

  return (
    <div
      className={cx(styles.collapsedLeftNav, {
        [styles.collapsedLeftNavDesktop]: !isMobile,
      })}
    >
      <Box as={Stack} gap="8">
        {navItems.map((props, key) => (
          <LinkItem key={key} {...props} />
        ))}
      </Box>
    </div>
  );
};

const LinkItem = ({
  onClick,
  text,
  path,
  icon,
  state,
}: {
  text: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  path: string;
  onClick?: () => void;
  extra?: React.ReactNode;
  state?: {[key: string]: any};
}) => {
  const location = useLocation();
  const isMobile = useAtomValue(isMobileAtom);

  const isActive =
    path === ROUTE_PATHS.HOME
      ? location.pathname === ROUTE_PATHS.HOME
      : location.pathname.startsWith(path);

  const linkContent = (
    <div>
      <Link
        to={path ?? location.pathname}
        onClick={onClick}
        state={{source: 'left-nav', ...state}}
        hasNoUnderline
      >
        {isMobile ? (
          <IconButton
            variant="transparent"
            shape="circular"
            tabIndex={-1}
            className={cx(styles.itemLink, {
              [styles.activeItemLink]: isActive,
            })}
          >
            <UIIcon src={icon} className={styles.itemIcon} />
          </IconButton>
        ) : (
          <Button
            variant="borderless"
            withIconStart={<UIIcon src={icon} className={styles.itemIcon} />}
            tabIndex={-1}
            className={cx(styles.itemLink, {
              [styles.activeItemLink]: isActive,
            })}
          >
            {text}
          </Button>
        )}
      </Link>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <Tooltip title={text} placement="right">
          {linkContent}
        </Tooltip>
      ) : (
        linkContent
      )}
    </>
  );
};
