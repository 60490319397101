import {Breadcrumb} from '@dropbox/dig-components/breadcrumb';
import {Text} from '@dropbox/dig-components/typography';
import {Team, useTeamSuspense} from 'components/teams/hooks';
import {To, useNavigate} from 'react-router-dom';

export interface BreadcrumbData {
  name: string;
  path?: To;
  isBold?: boolean;
}

export const TeamBreadcrumbs = ({
  teamSlug,
  formatDashboardsBreadcrumbs,
  prefixBreadcrumbs,
}: {
  teamSlug: string;
  formatDashboardsBreadcrumbs: (teamHierarchy: Team[]) => BreadcrumbData[];
  prefixBreadcrumbs?: BreadcrumbData[];
}) => {
  const team = useTeamSuspense({slug: teamSlug, includeHierarchy: true});
  const teamHierarchy = team?.hierarchy || [];
  const breadcrumbs = formatDashboardsBreadcrumbs(teamHierarchy);
  return <BreadcrumbsComponent breadcrumbs={breadcrumbs} prefixBreadcrumbs={prefixBreadcrumbs} />;
};

export const BreadcrumbsComponent = ({
  breadcrumbs,
  prefixBreadcrumbs = [],
}: {
  breadcrumbs: BreadcrumbData[];
  prefixBreadcrumbs?: BreadcrumbData[];
}) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb typography="text" size="small">
      {[...prefixBreadcrumbs, ...breadcrumbs].map((breadcrumb) => (
        <Breadcrumb.Link
          key={`breadcrumb__${breadcrumb.name}`}
          onClick={() => {
            if (breadcrumb.path) {
              navigate(breadcrumb.path);
            }
          }}
        >
          <Text color="subtle" isBold={breadcrumb.isBold}>
            {breadcrumb.name}
          </Text>
        </Breadcrumb.Link>
      ))}
    </Breadcrumb>
  );
};
