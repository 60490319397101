import {Button} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {formatTimeframe} from 'components/shared/TimeAgo';
import {GROUPED_TIMEFRAME_OPTIONS} from 'constant';
import {t} from 'i18next';

interface Props {
  selectedTimeframe: string;
  setSelectedTimeframe: (value: string) => void;
}

export const TimeframeSelector = ({selectedTimeframe, setSelectedTimeframe}: Props) => {
  return (
    <Menu.Wrapper onSelection={(value) => setSelectedTimeframe(value)}>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Box
            as={Button}
            {...getTriggerProps()}
            variant="borderless"
            withDropdownIcon
            style={{marginLeft: -8, marginTop: -4}}
          >
            {selectedTimeframe.split(' ')[0]} {selectedTimeframe.split(' ')[1].replace('FY', '20')}
          </Box>
          <Menu.Content {...getContentProps()}>
            <Menu.Segment>
              {GROUPED_TIMEFRAME_OPTIONS.current.map(({timeframe, date}: any) => (
                <Menu.SelectItem
                  key={timeframe}
                  value={timeframe}
                  withSubtitle={date}
                  withLeftAccessory={undefined}
                >
                  <Text>{formatTimeframe(timeframe)}</Text>
                  <Text size="small" isBold></Text>
                </Menu.SelectItem>
              ))}
            </Menu.Segment>
            <Menu.Segment>
              <Menu.Submenu withTriggerContent={t('previous_years')}>
                <Menu.Segment>
                  {Object.values(GROUPED_TIMEFRAME_OPTIONS.previous)
                    .flat()
                    .map(({timeframe, date}) => (
                      <Menu.SelectItem
                        key={timeframe}
                        value={timeframe}
                        withSubtitle={date}
                        withLeftAccessory={undefined}
                      >
                        <Text>{formatTimeframe(timeframe)}</Text>
                      </Menu.SelectItem>
                    ))}
                </Menu.Segment>
              </Menu.Submenu>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
