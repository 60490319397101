import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {TargetLine} from '@dropbox/dig-icons/assets';
import {useMyGoals} from 'components/goals/hooks';
import {NotificationCard} from 'components/notifications/NotificationCard';
import {useNotificationContext} from 'components/notifications/utils/useNotificationContext';
import {DueIn, isPastDue, toLocaleString} from 'components/shared/TimeAgo';
import {formatTimeframe} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {Trans} from 'react-i18next';

export const OKRAction = () => {
  const {
    upcoming,
    notification: {type, data, archived, updated_at, due_at},
  } = useNotificationContext();
  const {content, to, cta, data: timeframe} = data;

  const goals = useMyGoals({timeframe});
  const currentQuarterGoals = goals?.filter(
    (goal) => goal.timeframe === timeframe && !goal.is_draft
  );
  const goalsWithUpdates = currentQuarterGoals?.filter((goal) => goal.updates.length);
  const goalsWithGrades = currentQuarterGoals?.filter(
    (goal) =>
      goal.updates.length &&
      ['complete', 'cancelled', 'missed'].includes(goal.updates[goal.updates.length - 1].status)
  );

  const pastDue = !archived && isPastDue(due_at);

  return (
    <NotificationCard
      accessory={<Box as={UIIcon} src={TargetLine} color={pastDue ? 'Text Base' : 'Text Subtle'} />}
      variant={pastDue ? 'alert' : 'standard'}
      header={
        <Trans
          i18nKey={content}
          t={t}
          values={{
            data: type === 'okr_write' ? formatTimeframe(timeframe) : timeframe.split(' ')[0],
          }}
          components={{b: <Text isBold />}}
        />
      }
      subtext={
        <>
          {archived ? (
            t('notification_completed', {
              date: toLocaleString(updated_at),
            })
          ) : (
            <DueIn timestamp={due_at!} />
          )}
          {!archived && !upcoming && currentQuarterGoals?.length && type === 'okr_update' ? (
            <>
              {' • '}
              {goalsWithUpdates?.length} / {currentQuarterGoals.length} complete
            </>
          ) : null}
          {!archived && !upcoming && currentQuarterGoals?.length && type === 'okr_grade' ? (
            <>
              {' • '}
              {goalsWithGrades?.length} / {currentQuarterGoals.length} complete
            </>
          ) : null}
        </>
      }
      buttonLink={upcoming ? undefined : {cta, to, state: {timeframe}}}
      archived={archived}
    />
  );
};
