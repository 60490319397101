/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AIEvent } from '../models/AIEvent';
import type { AIEventCreate } from '../models/AIEventCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AiEventService {

    /**
     * Create Ai Event
     * Create a new AI event.
     * @param requestBody
     * @returns AIEvent Successful Response
     * @throws ApiError
     */
    public static createAiEventApiV1AiEventPost(
        requestBody: AIEventCreate,
    ): CancelablePromise<AIEvent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/ai_event',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
