import {IconButton, IconButtonProps} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/typography';
import {Box, BoxProps, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronLeftLine, ChevronRightLine} from '@dropbox/dig-icons/assets';
import {
  formatTimeframe,
  getNextQuarter,
  getPreviousQuarter,
  isCurrentQuarter,
  isPreviousQuarter,
} from 'components/shared/TimeAgo';

export const DashboardsTimeframeSelector = ({
  timeframe,
  setTimeframe,
}: {
  timeframe: string;
  setTimeframe: (timeframe: string) => void;
}) => {
  const iconButtonProps: BoxProps<'div'> & IconButtonProps = {
    size: 'small',
    variant: 'outline',
    shape: 'circular',
    color: 'Text Subtle',
    borderColor: 'Border Subtle',
    borderWidth: '1',
  };
  const previousQuarter = getPreviousQuarter(timeframe);
  const nextQuarter = getNextQuarter(timeframe);

  return (
    <Split style={{gap: '12px'}}>
      <Box
        as={IconButton}
        {...iconButtonProps}
        disabled={!isPreviousQuarter(previousQuarter)}
        onClick={() => setTimeframe(previousQuarter)}
      >
        <UIIcon src={ChevronLeftLine} size="small" />
      </Box>
      <Text isBold>{formatTimeframe(timeframe)}</Text>
      <Box
        as={IconButton}
        {...iconButtonProps}
        disabled={isCurrentQuarter(timeframe)}
        onClick={() => setTimeframe(nextQuarter)}
      >
        <UIIcon src={ChevronRightLine} size="small" />
      </Box>
    </Split>
  );
};
