import {Button} from '@dropbox/dig-components/dist/buttons';
import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {LineViewLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {useQuery} from '@tanstack/react-query';
import {pulseUserAtom} from 'atoms/auth';
import {ProjectService} from 'client';
import {AvatarIcon} from 'components/DSYS/Avatar';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {useAtomValue} from 'jotai';
import {useMemo} from 'react';
import {getService} from 'utilities';

export const ProjectDigest = () => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const {isLoading, data} = useQuery({
    queryKey: ['digest'],
    queryFn: getService(ProjectService).getDigestApiV1DigestProjectsGet,
  });

  const digests = useMemo(() => {
    const filtered = data?.digests.filter(({project}) => new Date(project.available));

    const sorted = filtered?.sort((a, b) => {
      const availableA = new Date(a.project.available);
      const availableB = new Date(b.project.available);
      return availableA.getTime() - availableB.getTime();
    });

    return sorted;
  }, [data?.digests]);

  if (isLoading) {
    return (
      <Layout.Container size="condensed">
        <Stack gap="16">
          <Button
            variant="outline"
            onClick={() => {
              getService(ProjectService).sendEmailApiV1ProjectsEmailGet();
            }}
          >
            Send digest email to {pulseUser?.email}
          </Button>
          <Title size="large" style={{marginBottom: 16}}>
            What happened in Projects on CompanyOS last week
          </Title>
          <Spinner />
        </Stack>
      </Layout.Container>
    );
  }

  return (
    <Layout.Container size="condensed">
      <Stack gap="16">
        <Button
          variant="outline"
          onClick={() => {
            getService(ProjectService).sendEmailApiV1ProjectsEmailGet();
          }}
        >
          Send digest email to {pulseUser?.email}
        </Button>
        <Box as={AvatarIcon} backgroundColor="Accent Green" src={LineViewLine} size="large" />
        <Box>
          <Title size="large">What happened in Projects on CompanyOS last week</Title>
        </Box>
        {digests?.map(({project, consolidated, dri}) => (
          <Stack key={project.id} gap="8" paddingY="16">
            <Split>
              <Split.Item width="fill">
                <Stack gap="4">
                  <Link to={`/projects/${project.id}`} hasNoUnderline>
                    <Title size={16}>{project.title}</Title>
                  </Link>
                  <Text color="subtle" size="small">
                    {project.team && (
                      <Link to={`team/${project.team.slug}`} hasNoUnderline monochromatic>
                        {project.team.name}
                      </Link>
                    )}
                    {project.team && dri && ' • '}
                    {dri && (
                      <Link to={`/people/${dri.ldap}`} hasNoUnderline monochromatic>
                        {dri.name}
                      </Link>
                    )}
                  </Text>
                  <Stack gap="4">
                    {consolidated.current_date && (
                      <Box as={Text} display="block" paddingLeft="2">
                        <Split gap="6">
                          <Split.Item paddingLeft="4">{'•'}</Split.Item>
                          <Split.Item>
                            <Text isBold>{consolidated.previous_date}</Text> →{' '}
                            <Text isBold>{consolidated.current_date}</Text>
                          </Split.Item>
                        </Split>
                      </Box>
                    )}
                    {consolidated.previous_status && (
                      <Box as={Text} display="block" paddingLeft="2">
                        <Split gap="6">
                          <Split.Item paddingLeft="4">{'•'}</Split.Item>
                          <Split.Item>
                            <Text isBold>{consolidated.previous_status}</Text> →{' '}
                            <Text isBold>{consolidated.current_status}</Text>
                          </Split.Item>
                        </Split>
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </Split.Item>
              <Split.Item>
                <ButtonLink to={`/projects/${project.id}`} variant="outline">
                  View
                </ButtonLink>
              </Split.Item>
            </Split>
          </Stack>
        ))}

        <Box paddingTop="16">
          <ButtonLink to="/projects" variant="primary">
            View all projects
          </ButtonLink>
        </Box>
      </Stack>
    </Layout.Container>
  );
};
