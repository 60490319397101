import {
  BreadcrumbData,
  BreadcrumbsComponent,
  TeamBreadcrumbs,
} from 'components/shared/TeamBreadcrumbs';
import {Team} from 'components/teams/hooks';
import {ROUTE_PATHS} from 'constant';
import {DASHBOARDS_DROPBOX_TEAM_SLUG} from 'views/dashboards/constants';
import {createTeamDashboardsPath} from 'views/dashboards/util';

interface DashboardsBreadcrumbsProps {
  teamHierarchy?: Team[];
  teamSlug?: string;
}

const formatDashboardsBreadcrumbs = (teamHierarchy: Team[]): BreadcrumbData[] => {
  return teamHierarchy.map((team) => ({
    name: team.name,
    path: createTeamDashboardsPath(team.slug),
  }));
};

export const DashboardsBreadcrumbs = ({teamHierarchy, teamSlug}: DashboardsBreadcrumbsProps) => {
  const prefixBreadcrumbs = [
    {name: 'Dashboards', path: ROUTE_PATHS.DASHBOARDS, isBold: true},
    {name: 'Dropbox', path: createTeamDashboardsPath(DASHBOARDS_DROPBOX_TEAM_SLUG)},
  ];
  if (!teamHierarchy?.length) {
    return teamSlug ? (
      <TeamBreadcrumbs
        teamSlug={teamSlug}
        formatDashboardsBreadcrumbs={formatDashboardsBreadcrumbs}
        prefixBreadcrumbs={prefixBreadcrumbs}
      />
    ) : (
      <BreadcrumbsComponent breadcrumbs={prefixBreadcrumbs} />
    );
  }
  const breadcrumbs = formatDashboardsBreadcrumbs(teamHierarchy);
  return <BreadcrumbsComponent breadcrumbs={breadcrumbs} prefixBreadcrumbs={prefixBreadcrumbs} />;
};
