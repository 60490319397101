import {List} from '@dropbox/dig-components/dist/list';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {Twinkle2Line} from '@dropbox/dig-icons/assets';
import {Employee, Goal, KeyResult, ProjectUpdate, Update} from 'client';
import {StatusButton} from 'components/DSYS/StatusButton';
import {MetricBar} from 'components/shared/MetricBar';
import {TimeAgo} from 'components/shared/TimeAgo';
import {isGoal} from 'helpers/utils';
import {useEmployee} from 'hooks/useEmployee';
import {t} from 'i18next';
import {ReactNode} from 'react';
import {Trans} from 'react-i18next';

import {Avatar, AvatarIcon} from './Avatar';

type PulseUpdate = {comment: string; created_at: string; status?: string};

const isGoalOrKr = (update: Update | ProjectUpdate | PulseUpdate): update is Update =>
  Boolean((update as Update)?.goal_id || (update as Update)?.key_result_id);

export const UpdatePost = ({
  ldap,
  featured,
  update,
  hideIcon,
  children,
  isAutomaticObjectiveStatusChange = false,
}: {
  ldap: string;
  featured?: boolean;
  hideIcon?: boolean;
  update: Update | ProjectUpdate | PulseUpdate | {created_at: string};
  children: ReactNode;
  isAutomaticObjectiveStatusChange?: boolean;
}) => {
  const {employee, isLoading} = useEmployee({ldap});

  if (isLoading || !employee) {
    return null;
  }

  return (
    <UpdatePost.Control
      icon={
        isAutomaticObjectiveStatusChange ? (
          <AvatarIcon src={Twinkle2Line} size="medium" />
        ) : (
          <Avatar user={employee} isInactive={employee.is_deactivated ?? false} />
        )
      }
      featured={featured}
    >
      {isAutomaticObjectiveStatusChange ? (
        <Box as={Text} variant="paragraph" display="block">
          {t('automatic_objective_status_change')}
        </Box>
      ) : (
        <UpdatePost.User employee={employee} />
      )}

      <UpdatePost.Status hideIcon={hideIcon} update={update}>
        {children}
      </UpdatePost.Status>
    </UpdatePost.Control>
  );
};

const Control = ({
  icon,
  children,
  featured,
}: {
  icon: React.ReactNode;
  featured?: boolean;
  children: React.ReactNode;
}) => (
  <Stack align="start" style={{boxSizing: 'border-box', width: '100%', position: 'relative'}}>
    <Box
      as={Split}
      gap="8"
      padding="16"
      paddingBottom="8"
      borderColor="Border Subtle"
      borderStyle={featured ? 'Solid' : undefined}
      backgroundColor={featured ? 'Background Base' : undefined}
      borderRadius="Medium"
    >
      <Split.Item>
        <List.Accessory>{icon}</List.Accessory>
      </Split.Item>
      <Split.Item>
        <List.Content>{children}</List.Content>
      </Split.Item>
    </Box>
  </Stack>
);
UpdatePost.Control = Control;

const User = ({employee}: {employee: Employee}) => (
  <div>
    <div>
      <Text style={{marginRight: '4px'}} isBold>
        {employee.name ?? ''}
      </Text>
      <Text size="small" color="faint">
        {employee.role ?? ''}
      </Text>
    </div>
  </div>
);
UpdatePost.User = User;

const Content = ({children}: {children: React.ReactNode}) => (
  <Text tagName="p" color="standard">
    {children}
  </Text>
);
UpdatePost.Content = Content;

const Status = ({
  update,
  hideIcon,
  children,
}: {
  update: Update | ProjectUpdate | PulseUpdate | {created_at: string};
  hideIcon?: boolean;
  children: React.ReactNode;
}) => (
  <>
    <TimeAgo timestamp={update.created_at!} />
    {'comment' in update ? (
      <>
        {update.comment !== 'automatic_objective_status_change' && (
          <UpdatePost.Content>{children}</UpdatePost.Content>
        )}
        {update.status && (
          <Box marginTop="8" display="flex" alignItems="center">
            <StatusButton hideIcon={hideIcon} status={update.status} isLabel />
            {!isGoalOrKr(update) ? null : (
              <Box marginLeft="8">
                <MetricBar
                  currentValue={update.current_metric}
                  targetValue={update.target_metric}
                />
              </Box>
            )}
          </Box>
        )}
      </>
    ) : (
      <UpdatePost.Content>{children}</UpdatePost.Content>
    )}
  </>
);
UpdatePost.Status = Status;

export const TimelineUpdateCreated = ({
  name,
  selected,
}: {
  name: string;
  selected: Goal | KeyResult;
}) => {
  return (
    <>
      <div>
        <Text color="faint">
          <Trans
            i18nKey={
              isGoal(selected) ? 'activity_goal_created_by' : 'activity_key_results_created_by'
            }
            t={t}
            values={{name}}
            components={{Text: <Text />}}
          />
        </Text>
      </div>
      <TimeAgo timestamp={selected.created_at!} />
    </>
  );
};
