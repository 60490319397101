import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Text} from '@dropbox/dig-components/dist/typography';
import {useTheme} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CheckmarkCircleFill,
  CircleSmallLine,
  CloseLine,
  ThumbsDownLine,
  ThumbsUpLine,
} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import DropboxOSBadgeIcon from 'assets/DropboxOSBadgeIcon.svg';
import DropboxOSBadgeDarkIcon from 'assets/DropboxOSBadgeIconDark.svg';
import axios from 'axios';
import {Link} from 'components/DSYS/Link';
import {GOOD_AI_GRADE} from 'constant';
import {t} from 'i18next';
import {useEffect, useRef, useState} from 'react';
import {Typewriter} from 'react-simple-typewriter';
import {getAIEventService, getBackendUrl} from 'utilities';

import styles from './GoalAIAssistant.module.css';

const GoalAIAssistant = (props: {
  idx: number;
  questionText?: string;
  onClose: () => void;
  onInsert: (idx: number, value: string) => void;
  showAIAssistant: boolean;
  goalValue?: string;
  type: 'goal' | 'key_result';
}) => {
  const [suggestion, setSuggestion] = useState<string>('');
  const [improvedStatement, setImprovedStatement] = useState<string>('');
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Create a ref for debounceTimeout
  const debounceTypingTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Create a ref for debounceTimeout

  //Todo: refactor to use one state for typing. This is a workaround to make sure the typing animation is done before showing the suggestion
  const [titleDoneTyping, setTitleDoneTyping] = useState(false);
  const [suggestionDoneTyping, setSuggestionDoneTyping] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const {mode} = useTheme();
  const revisions = useRef(0);

  const onInsertClick = () => {
    analyticsLogger().logEvent('OBJECTIVE_AI_INSERT', {revisions: revisions.current});
    props.onInsert(props.idx, improvedStatement);
    setIsCompleted(() => true);
  };

  const handleTitleDoneTyping = () => {
    setTitleDoneTyping(() => true);
  };

  const handleSuggestionDoneTyping = () => {
    setSuggestionDoneTyping(() => true);
  };

  const resetTypingState = () => {
    setTitleDoneTyping(() => false);
    setSuggestionDoneTyping(() => false);
  };

  const handleThumbUpClick = () => {
    const aiEvent = {
      new_content: improvedStatement,
      old_content: props.questionText ?? '',
      created_at: new Date().toISOString(),
      type: props.type + '_thumb_up',
      associated_id: props.idx,
    };
    if (props.type === 'goal') {
      analyticsLogger().logEvent('OBJECTIVE_AI_THUMB_UP');
      getAIEventService().createAiEventApiV1AiEventPost(aiEvent);
    } else {
      analyticsLogger().logEvent('KEY_RESULT_AI_THUMB_UP');
      getAIEventService().createAiEventApiV1AiEventPost(aiEvent);
    }
  };

  const handleThumbDownClick = () => {
    const aiEvent = {
      new_content: improvedStatement,
      old_content: props.questionText ?? '',
      created_at: new Date().toISOString(),
      type: props.type + '_thumb_down',
      associated_id: props.idx,
    };
    if (props.type === 'goal') {
      analyticsLogger().logEvent('OBJECTIVE_AI_THUMB_DOWN');
      getAIEventService().createAiEventApiV1AiEventPost(aiEvent);
    } else {
      analyticsLogger().logEvent('KEY_RESULT_AI_THUMB_DOWN');
      getAIEventService().createAiEventApiV1AiEventPost(aiEvent);
    }
  };

  useEffect(() => {
    analyticsLogger().logEvent('OBJECTIVE_AI_OPENED');
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (debounceTimeoutRef.current !== null) {
      clearTimeout(debounceTimeoutRef.current); // Use the ref to clear the timeout
    }

    if (debounceTypingTimeoutRef.current !== null) {
      clearTimeout(debounceTypingTimeoutRef.current); // Use the ref to clear the timeout
    }

    if (props.questionText !== improvedStatement) {
      setIsCompleted(() => {
        return false;
      });
    }

    if (props.questionText && !isCompleted) {
      resetTypingState();
      setSuggestion(() => '');
      setImprovedStatement(() => '');
      debounceTypingTimeoutRef.current = setTimeout(() => {
        setLoading(() => true);
        debounceTimeoutRef.current = setTimeout(() => {
          analyticsLogger().logEvent('OBJECTIVE_AI_REVIEW');
          revisions.current++;
          axios
            .post(
              props.type === 'goal'
                ? `${getBackendUrl()}/api/v1/goal/chatPrompt`
                : `${getBackendUrl()}/api/v1/key_result/chatPrompt`,

              props.type === 'goal'
                ? JSON.stringify({
                    message: props.questionText,
                  })
                : JSON.stringify({
                    message: props.questionText,
                    goal: props.goalValue,
                  }),
              {
                cancelToken: source.token,
                withCredentials: true,
              }
            )
            .then((response) => {
              const {grade, suggest, improved_statement: improvement} = response.data;
              setLoading(() => false);
              if (grade == GOOD_AI_GRADE) {
                analyticsLogger().logEvent('OBJECTIVE_AI_RESULT', {type: 'pass'});
                setImprovedStatement(() => props.questionText ?? '');
                setIsCompleted(() => true);
                return;
              }
              analyticsLogger().logEvent('OBJECTIVE_AI_RESULT', {
                type: improvement ? 'suggestion' : 'no_suggestion',
              });
              setSuggestion(() => suggest);
              setImprovedStatement(() => improvement);
            })
            .catch((error) => {
              analyticsLogger().logEvent('OBJECTIVE_AI_RESULT', {type: 'error'});
              setLoading(() => false);
              if (!axios.isCancel(error)) {
                // Only log error if not cancelled
                console.error(error);
                setSuggestion(t('error_message_for_ai_asisstant').toString());
              }
            });
        }, 500);
      }, 2000);
    }

    return () => {
      if (debounceTypingTimeoutRef.current !== null) {
        clearTimeout(debounceTypingTimeoutRef.current);
      }
      if (debounceTimeoutRef.current !== null) {
        clearTimeout(debounceTimeoutRef.current);
      }
      source.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questionText, isCompleted, t]);

  if ((!props.questionText || !suggestion) && !loading && !isCompleted) {
    return (
      <GoalAssistantStaticState
        onClose={props.onClose}
        showAIAssistant={props.showAIAssistant}
        type={props.type}
      />
    );
  }

  if (isCompleted) {
    return (
      <GoalAssistantStaticState
        onClose={props.onClose}
        showAIAssistant={props.showAIAssistant}
        inputText={props.questionText}
        isCompleted={isCompleted}
        type={props.type}
      />
    );
  }

  return (
    <div className={styles.drawerContainer}>
      <div className={`${styles.container} ${props.showAIAssistant ? styles.open : ''}`}>
        <IconButton
          variant="borderless"
          size="medium"
          shape="circular"
          className={styles.close}
          onClick={props.onClose}
        >
          <UIIcon src={CloseLine} size="medium" />
        </IconButton>
        <div className={styles.header}>
          <div className={styles.badges}>
            <img
              src={mode === 'dark' ? DropboxOSBadgeDarkIcon : DropboxOSBadgeIcon}
              alt="Dropbox OS Badge"
              width="28"
              height="28"
            />
          </div>
          <div className={styles.texts}>
            {loading ? (
              <>
                <Text size="large" isBold>
                  {props.type === 'goal' ? t('review_your_input') : t('review_your_key_result')}
                </Text>
                <Spinner size="small" />
              </>
            ) : (
              <>
                <Text size="large" isBold>
                  <Typewriter
                    words={[t('lets_improve')]}
                    typeSpeed={3}
                    onLoopDone={handleTitleDoneTyping}
                  />
                </Text>
                {titleDoneTyping && (
                  <Text size="medium" color="faint">
                    <Typewriter
                      words={[suggestion]}
                      typeSpeed={3}
                      onLoopDone={handleSuggestionDoneTyping}
                    />
                  </Text>
                )}
              </>
            )}
          </div>
        </div>
        {improvedStatement && !loading && suggestionDoneTyping && (
          <div className={styles.body}>
            <div className={styles.main}>
              <Text size="medium">
                <Typewriter words={[improvedStatement]} typeSpeed={3} />
              </Text>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Button variant="opacity" size="medium" fullWidth={false} onClick={onInsertClick}>
                {t('insert_button_title')}
              </Button>
              <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                <IconButton
                  variant="outline"
                  size="small"
                  shape="circular"
                  onClick={handleThumbUpClick}
                >
                  <UIIcon src={ThumbsUpLine} />
                </IconButton>
                <IconButton
                  variant="outline"
                  size="small"
                  shape="circular"
                  onClick={handleThumbDownClick}
                >
                  <UIIcon src={ThumbsDownLine} />
                </IconButton>
              </div>
            </div>
          </div>
        )}

        {!loading && <DisclaimerComponent />}
      </div>
    </div>
  );
};

const GoalAssistantStaticState = (props: {
  showAIAssistant: boolean;
  onClose: () => void;
  isTyping?: boolean;
  inputText?: string;
  isCompleted?: boolean;
  type?: 'goal' | 'key_result';
}) => {
  const {mode} = useTheme();

  const items =
    props.type === 'goal'
      ? [
          {
            text: t('inspirational'),
            subText: t('inspirational_subtitle'),
          },
          {
            text: t('outcome_oriented'),
            subText: t('outcome_oriented_subtitle'),
          },
          {
            text: t('important'),
            subText: t('important_subtitle'),
          },
        ]
      : [
          {
            text: t('measurable'),
            subText: t('measurable_subtitle'),
          },
          {
            text: t('achievable'),
            subText: t('achievable_subtitle'),
          },
          {
            text: t('aligned_with_objective'),
            subText: t('aligned_with_objective_subtitle'),
          },
        ];

  return (
    <div className={styles.drawerContainer}>
      <div className={`${styles.container} ${props.showAIAssistant ? styles.open : ''}`}>
        <IconButton
          variant="borderless"
          size="medium"
          shape="circular"
          className={styles.close}
          onClick={props.onClose}
        >
          <UIIcon src={CloseLine} size="medium" />
        </IconButton>
        <div className={styles.header}>
          <div className={styles.badges}>
            <img
              src={mode === 'dark' ? DropboxOSBadgeDarkIcon : DropboxOSBadgeIcon}
              alt="Dropbox OS Badge"
              width="28"
              height="28"
            />
          </div>
          {props.isCompleted ? (
            <div className={styles.texts}>
              {props.type === 'goal' ? (
                <>
                  <Text size="large" isBold>
                    {t('exellent_objective')}
                  </Text>
                  <Text size="medium" color="faint">
                    {t('exellent_objective_subtitle')}
                  </Text>
                </>
              ) : (
                <>
                  <Text size="large" isBold>
                    {t('exellent_key_result')}
                  </Text>
                  <Text size="medium" color="faint">
                    {t('exellent_key_result_subtitle')}
                  </Text>
                </>
              )}
            </div>
          ) : (
            <div className={styles.texts}>
              {props.type === 'goal' ? (
                <>
                  <Text size="large" isBold>
                    {t('craft_better_objective')}
                  </Text>
                  <Text size="medium" color="faint">
                    {t('start_writing_objective_and_improve_your_goal')}
                  </Text>
                  <Text size="medium" color="faint">
                    {t('a_great_objective_is')}
                  </Text>
                </>
              ) : (
                <>
                  <Text size="large" isBold>
                    {t('improve_key_result')}
                  </Text>
                  <Text size="medium" color="faint">
                    {t('start_writing_your_key_result')}
                  </Text>
                </>
              )}
            </div>
          )}
        </div>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.icon}>
                {!props.isCompleted ? (
                  <UIIcon src={CircleSmallLine} size="medium" className={styles.iconDefault} />
                ) : (
                  <UIIcon src={CheckmarkCircleFill} size="medium" className={styles.iconCheck} />
                )}
              </div>
              <div className={styles.text}>
                <Text size="medium" isBold>
                  {item.text}
                </Text>
                <Text size="medium" color="faint">
                  {item.subText}
                </Text>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const DisclaimerComponent = () => {
  return (
    <div className={styles.disclaimer}>
      <Text size="xsmall" color="faint" style={{marginBottom: '36px'}}>
        {t('goal_ai_assistant_disclaimer')}{' '}
        <Link
          to="https://dropbox.slack.com/archives/companyos-feedback"
          monochromatic
          target="_blank"
        >
          {t('companyos_feedback')}
        </Link>
      </Text>
    </div>
  );
};

export default GoalAIAssistant;
