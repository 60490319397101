import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, GratitudeLine, PersonMultipleLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {SharingCakeMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {HomeService, MyPeople} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {EmptyState} from 'components/DSYS/EmptyState';
import {Link} from 'components/DSYS/Link';
import {format, parseISO} from 'date-fns';
import {t} from 'i18next';
import {useCallback, useEffect, useState} from 'react';
import {getService} from 'utilities';

import styles from './People.module.css';
import {Section} from './Section';

const getYearsAtCompany = (tenure: number) => {
  const yearsAtCompany = Number(tenure) / 12;

  return t('year', {count: yearsAtCompany});
};

const formatLeaveDate = (dateUnformatted: string): string => {
  const parsedDate = parseISO(dateUnformatted);
  return format(parsedDate, 'MMM d');
};

const getSubtext = (employee: MyPeople) => {
  if (employee.reason === 'birthday' && employee.birthday) {
    return t('home_people_birthday', {date: employee.birthday});
  }
  if (employee.reason === 'anniversary' && employee.tenure) {
    return t('home_people_anniversary', {tenure: getYearsAtCompany(employee.tenure)});
  }
  if (employee.reason === 'pto') {
    const startDate = employee.upcoming_pto
      ? formatLeaveDate(employee.upcoming_pto.split(' ')[0])
      : null;
    const endDate = employee.upcoming_pto
      ? formatLeaveDate(employee.upcoming_pto.split(' ')[1])
      : null;

    return t(startDate === endDate ? 'home_people_pto' : 'home_people_pto_range', {
      date: startDate === endDate ? startDate : `${startDate} - ${endDate}`,
    });
  }
  if (employee.reason === 'new') {
    return t('home_people_new', {role: employee.role});
  }
  return '';
};

const defaultEmployeeCount = 11;

const options = ['all', 'pto', 'birthday', 'new', 'anniversary'];

export const PeopleSection = ({setStretch}: {setStretch: (stretch: boolean) => void}) => {
  const [selected, setSelected] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [filter, setFilter] = useState<'all' | 'birthday' | 'anniversary' | 'pto' | 'new'>('all');

  const {data, isLoading} = useQuery({
    queryKey: ['home', 'people'],
    queryFn: () =>
      getService(HomeService).peopleApiV1HomePeopleGet(
        encodeURIComponent(new Date().toISOString())
      ),
  });

  const handleToggle = useCallback(({isOpen}: {isOpen: boolean}) => {
    setSelected(isOpen);
  }, []);

  const filteredEmployees = data?.filter(({reason}) => reason === filter || filter === 'all');
  const shownEmployees = showMore
    ? filteredEmployees
    : filteredEmployees?.slice(0, defaultEmployeeCount);

  const handleEventClick = (type: string) =>
    analyticsLogger().logEvent('HOME_PEOPLE_CLICKED', {type});

  const handleSetFilter = (type: typeof filter) => {
    setFilter(type);
    analyticsLogger().logEvent('HOME_PEOPLE_FILTER', {type});
  };

  useEffect(() => {
    setStretch((shownEmployees?.length ?? 0) <= 9);
  }, [setStretch, shownEmployees?.length]);

  return (
    <>
      <Section
        title={
          <Link hasNoUnderline to="/people" onClick={() => handleEventClick('header')}>
            {t('home_my_people')}
          </Link>
        }
        withAccessoryStart={<UIIcon src={PersonMultipleLine} />}
        withAccessoryEnd={
          <Menu.Wrapper onToggle={handleToggle} onSelection={handleSetFilter}>
            {({getTriggerProps, getContentProps}) => (
              <>
                <Box
                  as={Chip}
                  variant="transparent"
                  size="small"
                  withDropdownIcon
                  {...getTriggerProps()}
                  isSelected={selected}
                >
                  <Chip.Content>{t(`home_filter_${filter}`)}</Chip.Content>
                </Box>
                <Menu.Content {...getContentProps()} style={{minWidth: 220}} placement="bottom-end">
                  <Menu.Segment>
                    {options.map((option) => (
                      <Menu.ActionItem
                        key={option}
                        value={option}
                        withRightAccessory={filter === option && <UIIcon src={CheckmarkLine} />}
                      >
                        {t(`home_filter_${option}`)}
                      </Menu.ActionItem>
                    ))}
                  </Menu.Segment>
                </Menu.Content>
              </>
            )}
          </Menu.Wrapper>
        }
        isLoading={isLoading}
        loadingSkeleton={
          <Stack gap="0">
            {Array.from({length: 4}).map((_, index) => (
              <Split key={index} alignY="center">
                <Split.Item key={index} width="fill">
                  <Box
                    paddingY="8"
                    paddingX="12"
                    borderRadius="Medium"
                    {...withShade({
                      duration: 'None',
                      style: {marginLeft: -8, opacity: (10 - index * 2) / 8},
                    })}
                  >
                    <LabelGroup
                      size="large"
                      withLeftAccessory={<Skeleton.Avatar />}
                      withText={<Box as={Skeleton.Text} style={{width: 100}} />}
                      withSubtext={<Skeleton.Text size="small" width={200} />}
                    />
                  </Box>
                </Split.Item>
              </Split>
            ))}
          </Stack>
        }
        content={
          !isLoading && (
            <Stack>
              {shownEmployees?.map((employee) => (
                <Box
                  key={`${employee.user_id}-${employee.reason}`}
                  paddingY="8"
                  paddingX="12"
                  borderRadius="Medium"
                  cursor="pointer"
                  {...withShade({duration: 'None', style: {marginLeft: -8}})}
                >
                  <Link
                    to={`/people/${employee.ldap}`}
                    state={{source: 'home'}}
                    onClick={() => handleEventClick('person')}
                    hasNoUnderline
                  >
                    <Split className={styles.person} alignY="center">
                      <Split.Item width="fill">
                        <LabelGroup
                          withLeftAccessory={<Avatar user={employee} />}
                          withText={<Text isBold>{employee.name}</Text>}
                          withSubtext={
                            <Text size="small" color="faint">
                              {getSubtext(employee)}
                            </Text>
                          }
                        />
                      </Split.Item>
                      {['birthday', 'anniversary', 'new'].includes(employee.reason ?? '') && (
                        <Split.Item className={styles.button}>
                          <Link
                            to={`/gratitude?to=${employee.ldap}`}
                            hasNoUnderline
                            onClick={() => handleEventClick('gratitude')}
                          >
                            <Tooltip title={t('pick_a_card')}>
                              <IconButton variant="borderless">
                                <UIIcon src={GratitudeLine} />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </Split.Item>
                      )}
                    </Split>
                  </Link>
                </Box>
              ))}
              {filteredEmployees?.length ? (
                Boolean(filteredEmployees?.length > defaultEmployeeCount) && (
                  <Box
                    as={Button}
                    variant="transparent"
                    size="small"
                    onClick={() => setShowMore(!showMore)}
                    paddingTop="12"
                  >
                    {showMore ? t('show_less') : t('show_more')}
                  </Box>
                )
              ) : (
                <EmptyState
                  title={t('home_empty_people_title')}
                  body={t('home_empty_people_body')}
                  image={<SharingCakeMini width="64" altText={t('home_empty_news_title')} />}
                  hideBorder
                />
              )}
            </Stack>
          )
        }
      />
    </>
  );
};
