/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Employee } from '../models/Employee';
import type { EmployeeResponse } from '../models/EmployeeResponse';
import type { Pulse } from '../models/Pulse';
import type { PulseCreate } from '../models/PulseCreate';
import type { PulseGrouping } from '../models/PulseGrouping';
import type { PulseResponseWithEmployee } from '../models/PulseResponseWithEmployee';
import type { PulseUpdate } from '../models/PulseUpdate';
import type { PulseWithGrouping } from '../models/PulseWithGrouping';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PulseService {

    /**
     * Seed
     * @returns any Successful Response
     * @throws ApiError
     */
    public static seedApiV1SeedPulseGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/seed/pulse',
        });
    }

    /**
     * Active
     * @returns any Successful Response
     * @throws ApiError
     */
    public static activeApiV1PulseActiveGet(): CancelablePromise<(Array<EmployeeResponse> | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse/active',
        });
    }

    /**
     * Demo
     * @returns any Successful Response
     * @throws ApiError
     */
    public static demoApiV1PulseDemoGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse/demo',
        });
    }

    /**
     * Latest
     * @param id
     * @returns PulseWithGrouping Successful Response
     * @throws ApiError
     */
    public static latestApiV1PulseGet(
        id?: number,
    ): CancelablePromise<PulseWithGrouping> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Pulse
     * @param requestBody
     * @returns Pulse Successful Response
     * @throws ApiError
     */
    public static updatePulseApiV1PulsePost(
        requestBody: PulseUpdate,
    ): CancelablePromise<Pulse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pulse',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employees
     * @param group
     * @param slug
     * @returns Employee Successful Response
     * @throws ApiError
     */
    public static getEmployeesApiV1PulseGroupSlugEmployeeGet(
        group: string,
        slug: string,
    ): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse/{group}/{slug}/employee',
            path: {
                'group': group,
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Bugbash Pulse
     * @param team
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendBugbashPulseApiV1PulseBugbashSendGet(
        team: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse/bugbash/send',
            query: {
                'team': team,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Finalize Bugbash Pulse
     * @returns any Successful Response
     * @throws ApiError
     */
    public static finalizeBugbashPulseApiV1PulseBugbashFinalizeGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse/bugbash/finalize',
        });
    }

    /**
     * Create Pulse
     * @param requestBody
     * @returns Pulse Successful Response
     * @throws ApiError
     */
    public static createPulseApiV1PulseCreatePost(
        requestBody: PulseCreate,
    ): CancelablePromise<Pulse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pulse/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteApiV1PulseScheduledDeletePost(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pulse/scheduled/delete',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Scheduled
     * @returns PulseWithGrouping Successful Response
     * @throws ApiError
     */
    public static scheduledApiV1PulseScheduledGet(): CancelablePromise<Array<PulseWithGrouping>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse/scheduled',
        });
    }

    /**
     * Pulse Table Group
     * @param group
     * @param id
     * @returns PulseGrouping Successful Response
     * @throws ApiError
     */
    public static pulseTableGroupApiV1PulseGroupIdGet(
        group: string,
        id: number,
    ): CancelablePromise<Array<PulseGrouping>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse/{group}/{id}',
            path: {
                'group': group,
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Responses
     * @param group
     * @param id
     * @param questionId
     * @returns PulseResponseWithEmployee Successful Response
     * @throws ApiError
     */
    public static getResponsesApiV1PulseGroupIdQuestionQuestionIdGet(
        group: string,
        id: string,
        questionId: number,
    ): CancelablePromise<Array<PulseResponseWithEmployee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse/{group}/{id}/question/{question_id}',
            path: {
                'group': group,
                'id': id,
                'question_id': questionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pulse Group
     * @param group
     * @param id
     * @param pulseId
     * @returns PulseWithGrouping Successful Response
     * @throws ApiError
     */
    public static getPulseGroupApiV1PulseGroupIdPulseIdGet(
        group: string,
        id: string,
        pulseId: number,
    ): CancelablePromise<PulseWithGrouping> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pulse/{group}/{id}/{pulse_id}',
            path: {
                'group': group,
                'id': id,
                'pulse_id': pulseId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Handle Slack Actions
     * @returns any Successful Response
     * @throws ApiError
     */
    public static handleSlackActionsApiV1SlackActionsPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/slack/actions',
        });
    }

}
