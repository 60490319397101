import {useQueries, useSuspenseQuery} from '@tanstack/react-query';
import {DashboardService} from 'client';
import {getService} from 'utilities';
import {DASHBOARDS_DROPBOX_TEAM_SLUG} from 'views/dashboards/constants';

export type DashboardsGoalCounts = {
  team_slug: string;
  total_counts: {[status: string]: number};
  members_counts: {[ldap: string]: {[status: string]: number}};
  subteams_counts: {[slug: string]: {[status: string]: number}};
};

export const useDashboardsGoalCountsSuspense = ({
  teamSlug,
  timeframe,
  includeMembersCounts = false,
  includeSubteamsCounts = false,
}: {
  teamSlug: string;
  timeframe: string;
  includeMembersCounts?: boolean;
  includeSubteamsCounts?: boolean;
}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['dashboards', teamSlug, timeframe, includeMembersCounts, includeSubteamsCounts],
    queryFn: () =>
      getService(DashboardService).goalsCountApiV1DashboardsGoalsCountGet(
        teamSlug,
        timeframe,
        includeMembersCounts,
        includeSubteamsCounts
      ),
  });

  return data as DashboardsGoalCounts;
};

export const useDashboardsDropboxGoalCountsSuspense = ({
  teamSlugs,
  timeframe,
  includeMembersCounts = false,
  includeSubteamsCounts = false,
}: {
  teamSlugs: string[];
  timeframe: string;
  includeMembersCounts?: boolean;
  includeSubteamsCounts?: boolean;
}) => {
  const queries = useQueries({
    queries: teamSlugs.map((teamSlug) => ({
      queryKey: ['dashboards', teamSlug, timeframe, includeMembersCounts, includeSubteamsCounts],
      queryFn: () =>
        getService(DashboardService).goalsCountApiV1DashboardsGoalsCountGet(
          teamSlug,
          timeframe,
          includeMembersCounts,
          includeSubteamsCounts
        ),
      throwOnError: false,
      suspense: true,
    })),
  });

  const subteams_counts: {[slug: string]: {[status: string]: number}} = {};
  queries.forEach(({data}) => {
    if (!data) {
      return;
    }
    const queryData = data as DashboardsGoalCounts;
    subteams_counts[queryData.team_slug] = queryData.total_counts;
  });

  const total_counts: {[status: string]: number} = {};
  queries.forEach(({data}) => {
    if (!data) {
      return;
    }
    const queryData = data as DashboardsGoalCounts;
    Object.keys(queryData.total_counts).forEach((status) => {
      total_counts[status] = (total_counts[status] || 0) + queryData.total_counts[status];
    });
  });

  return {
    team_slug: DASHBOARDS_DROPBOX_TEAM_SLUG,
    total_counts,
    members_counts: {},
    subteams_counts,
  };
};
