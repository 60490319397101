import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Cluster, Split, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Goal, GoalData, GoalRead, KeyResult} from 'client';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {Facepile} from 'components/DSYS/Facepile';
import {Link} from 'components/DSYS/Link';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {getStatusStyle} from 'components/DSYS/StatusButtonIcon';
import {UpdatePost} from 'components/DSYS/UpdatePost';
import {useGoal, useKeyResult} from 'components/goals/hooks';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {emailToLdap} from 'utilities';
import {SubtleKeyResultIcon, SubtleObjectiveIcon} from 'views/goals_v2/icons';
import {getBrowsePath, getRowData} from 'views/goals_v2/utils';

import {DrawerSection, DrawerSectionTitle} from './DrawerSection';

export const GoalDetailsDrawerBody = ({
  goal,
  keyResult,
  parentGoalId,
  setIsGoalDetailsDrawerOpen,
}: {
  goal: Goal;
  keyResult?: KeyResult;
  parentGoalId?: number;
  setIsGoalDetailsDrawerOpen: (isOpen: boolean) => void;
}) => {
  return (
    <>
      <LatestUpdateSection goal={goal} keyResult={keyResult} />
      <ParentGoalSection
        goal={goal}
        keyResult={keyResult}
        parentGoalId={parentGoalId}
        setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen}
      />
      {!keyResult && (
        <KeyResultsSection goal={goal} setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen} />
      )}
      <SubGoalsSection
        goal={goal}
        keyResult={keyResult}
        setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen}
      />
    </>
  );
};

const LatestUpdateSection = ({goal, keyResult}: {goal: Goal; keyResult?: KeyResult}) => {
  const {t} = useTranslation();
  const [showAllPreviousUpdates, setShowAllPreviousUpdates] = useState(false);
  const row = keyResult ? keyResult : goal;
  const goalOwner = goal.users![0];
  const {latestUpdate, previousUpdates} = getRowData(row, goalOwner);
  const latestUpdateEmployee = latestUpdate?.employee;
  if (!latestUpdate) {
    return null;
  }

  const isGoalRow = !keyResult;

  return (
    <DrawerSection>
      <Cluster alignX="between" style={{height: '32px'}}>
        <DrawerSectionTitle>
          <Box as={Eyebrow} color="Text Subtle" size="xsmall">
            {t('latest_update')}
          </Box>
        </DrawerSectionTitle>
        {previousUpdates.length > 0 && (
          <Box
            as={Button}
            variant="transparent"
            size="small"
            marginRight="8"
            onClick={() => setShowAllPreviousUpdates(!showAllPreviousUpdates)}
          >
            {showAllPreviousUpdates ? t('view_less') : t('view_all')}
          </Box>
        )}
      </Cluster>
      <Box marginTop="8" marginBottom="8">
        <UpdatePost
          featured
          ldap={latestUpdateEmployee ? latestUpdateEmployee.ldap : goalOwner.email.split('@')[0]}
          update={latestUpdate}
          isAutomaticObjectiveStatusChange={
            isGoalRow && latestUpdate.comment === 'automatic_objective_status_change'
          }
        >
          <RichTextArea value={latestUpdate.comment} />
        </UpdatePost>
      </Box>
      {previousUpdates.length > 0 && showAllPreviousUpdates && (
        <PreviousUpdateSection goal={goal} keyResult={keyResult} />
      )}
    </DrawerSection>
  );
};

const PreviousUpdateSection = ({goal, keyResult}: {goal: Goal; keyResult?: KeyResult}) => {
  const {t} = useTranslation();
  const row = keyResult ? keyResult : goal;
  const goalOwner = goal.users![0];
  const {previousUpdates} = getRowData(row, goalOwner);
  const isGoalRow = !keyResult;
  return (
    <Box marginTop="16">
      <DrawerSection>
        <DrawerSectionTitle>
          <Box as={Eyebrow} color="Text Subtle" size="xsmall" marginTop="16">
            {t('previous_updates', {count: previousUpdates.length})}
          </Box>
        </DrawerSectionTitle>
        <Box marginTop="8">
          {previousUpdates.reverse().map((update) => {
            const updateLdap = update?.employee?.ldap || goalOwner.email.split('@')[0];
            return (
              <UpdatePost
                key={update.id}
                ldap={updateLdap}
                update={update}
                isAutomaticObjectiveStatusChange={
                  isGoalRow && update.comment === 'automatic_objective_status_change'
                }
              >
                <RichTextArea value={update.comment} />
              </UpdatePost>
            );
          })}
        </Box>
      </DrawerSection>
    </Box>
  );
};

const ParentGoalSection = ({
  goal,
  keyResult,
  parentGoalId,
  setIsGoalDetailsDrawerOpen,
}: {
  goal: Goal;
  keyResult?: KeyResult;
  parentGoalId?: number;
  setIsGoalDetailsDrawerOpen: (isOpen: boolean) => void;
}) => {
  const {t} = useTranslation();
  const parentGoal = keyResult ? goal : goal.parent;
  if (!parentGoal && goal.key_result_parent_id) {
    if (parentGoalId) {
      return (
        <ParentKeyResultSection
          parentGoalId={parentGoalId}
          parentKeyResultId={goal.key_result_parent_id}
          setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen}
        />
      );
    } else {
      return (
        <LazyLoadParentKeyResultSection
          parentKeyResultId={goal.key_result_parent_id}
          setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen}
        />
      );
    }
  }
  if (!parentGoal) {
    return null;
  }

  return (
    <DrawerSection>
      <DrawerSectionTitle>
        <Box as={Eyebrow} color="Text Subtle" size="xsmall">
          {t('parent_goal')}
        </Box>
      </DrawerSectionTitle>
      <DrawerRow
        source="parent"
        goal={parentGoal}
        setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen}
      />
    </DrawerSection>
  );
};

const LazyLoadParentKeyResultSection = ({
  parentKeyResultId,
  setIsGoalDetailsDrawerOpen,
}: {
  parentKeyResultId: number;
  setIsGoalDetailsDrawerOpen: (isOpen: boolean) => void;
}) => {
  const keyResult = useKeyResult({keyResultId: parentKeyResultId});
  return (
    <ParentKeyResultSection
      parentGoalId={keyResult.goal_id}
      parentKeyResultId={parentKeyResultId}
      setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen}
    />
  );
};

const ParentKeyResultSection = ({
  parentGoalId,
  parentKeyResultId,
  setIsGoalDetailsDrawerOpen,
}: {
  parentGoalId: number;
  parentKeyResultId: number;
  setIsGoalDetailsDrawerOpen: (isOpen: boolean) => void;
}) => {
  const {t} = useTranslation();
  const parentGoal = useGoal({goalId: parentGoalId});
  const parentKeyResult = parentGoal?.key_results.find(({id}) => id === parentKeyResultId);
  if (!parentKeyResult) {
    return null;
  }

  return (
    <DrawerSection>
      <DrawerSectionTitle>
        <Box as={Eyebrow} color="Text Subtle" size="xsmall">
          {t('parent_goal')}
        </Box>
      </DrawerSectionTitle>
      <DrawerRow
        source="parent"
        goal={parentGoal}
        keyResult={parentKeyResult}
        setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen}
      />
    </DrawerSection>
  );
};

const KeyResultsSection = ({
  goal,
  setIsGoalDetailsDrawerOpen,
}: {
  goal: Goal;
  setIsGoalDetailsDrawerOpen: (isOpen: boolean) => void;
}) => {
  const {t} = useTranslation();
  const keyResults = goal.key_results;
  if (!keyResults.length) {
    return null;
  }

  return (
    <DrawerSection>
      <DrawerSectionTitle>
        <Box as={Eyebrow} color="Text Subtle" size="xsmall">
          {t('key_results')}
        </Box>
      </DrawerSectionTitle>
      {keyResults.map((keyResult) => (
        <DrawerRow
          source="kr"
          key={`goal-details__key-result__${keyResult.id}`}
          goal={goal}
          keyResult={keyResult}
          setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen}
        />
      ))}
    </DrawerSection>
  );
};

const SubGoalsSection = ({
  goal,
  keyResult,
  setIsGoalDetailsDrawerOpen,
}: {
  goal: GoalData;
  keyResult?: KeyResult;
  setIsGoalDetailsDrawerOpen: (isOpen: boolean) => void;
}) => {
  const {t} = useTranslation();
  const subGoals = keyResult
    ? goal.key_results_aligned_goals?.filter(
        (alignedGoal) => alignedGoal.key_result_parent_id === keyResult.id
      )
    : goal.children;
  if (!subGoals?.length) {
    return null;
  }

  return (
    <DrawerSection>
      <DrawerSectionTitle>
        <Box as={Eyebrow} color="Text Subtle" size="xsmall">
          {t('subgoal')}
        </Box>
      </DrawerSectionTitle>
      {subGoals.map((subGoal) => (
        <DrawerRow
          source="subgoal"
          key={`goal-details__sub-goal__${subGoal.id}`}
          goal={subGoal}
          setIsGoalDetailsDrawerOpen={setIsGoalDetailsDrawerOpen}
        />
      ))}
    </DrawerSection>
  );
};

const DrawerRow = ({
  source,
  goal,
  keyResult,
  setIsGoalDetailsDrawerOpen,
}: {
  source: string;
  goal: Goal | GoalRead;
  keyResult?: KeyResult;
  setIsGoalDetailsDrawerOpen: (isOpen: boolean) => void;
}) => {
  const {t} = useTranslation();
  const row = keyResult ? keyResult : goal;
  const goalId = goal.id;
  const keyResultId = keyResult?.id;
  const icon = keyResult ? SubtleKeyResultIcon : SubtleObjectiveIcon;
  const goalOwner = goal.users![0];
  const {currentRowGoalOwner, numKeyResults, contributorLdaps} = getRowData(row, goalOwner);
  const browsePath = getBrowsePath({goalId, keyResultId});

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        analyticsLogger().logEvent('GOAL_DETAILS_DRAWER_ROW_CLICK', {
          type: keyResultId ? 'kr' : 'objective',
          source,
        });
        setIsGoalDetailsDrawerOpen(true);
      }
    },
    [source, keyResultId, setIsGoalDetailsDrawerOpen]
  );

  return (
    <Box
      as={Link}
      to={browsePath}
      hasNoUnderline
      onClick={onClick}
      state={{source: 'drawer'}}
      preventScrollReset
    >
      <Box display="flex" width="100%" marginTop="8">
        <Box
          paddingX="16"
          paddingY="12"
          paddingLeft="12"
          borderColor="Border Subtle"
          borderStyle="Solid"
          borderRadius="Medium"
          width="100%"
          {...withShade({
            style: {
              borderRight: 'none',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          })}
        >
          <Box as={Split} gap="4">
            <Split.Item width="fill">
              <LabelGroup
                align="top"
                withLeftAccessory={<Box as={UIIcon} src={icon} color="Text Subtle" />}
                withText={<Text style={{hyphens: 'auto'}}>{row.title}</Text>}
                withSubtext={
                  !!numKeyResults && (
                    <Text color="subtle" size="small">
                      {t('key_result', {count: numKeyResults})}
                    </Text>
                  )
                }
              />
            </Split.Item>
            <Split.Item>
              <Facepile
                size="small"
                ownerIsFirst
                ldaps={[emailToLdap(currentRowGoalOwner.email), ...contributorLdaps]}
              />
            </Split.Item>
          </Box>
        </Box>

        <Box
          display="block"
          borderRadius="Medium"
          borderColor="Border Subtle"
          borderStyle="Solid"
          style={{
            width: 6,
            backgroundColor: getStatusStyle(
              keyResultId
                ? keyResult.updates.length
                  ? keyResult.updates?.[keyResult.updates.length - 1].status
                  : undefined
                : goal.updates.length
                ? goal.updates?.[goal.updates.length - 1].status
                : undefined
            ).color,
            borderLeft: 'none',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      </Box>
    </Box>
  );
};
