import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {TargetLine} from '@dropbox/dig-icons/assets';
import {isMobileAtom} from 'atoms/layout';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {DashboardsGoalCounts} from 'hooks/useDashboardsGoalCounts';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {DASHBOARD_STATUSES, DASHBOARDS_SEGMENT_STATUS_COLORS} from 'views/dashboards/constants';
import {DashboardsChartContainer} from 'views/dashboards/DashboardsChartContainer';
import {DashboardsGoalsOverviewGraph} from 'views/dashboards/DashboardsGoalsOverviewGraph';
import {getStatusColorOverride, getStatusGoalCountsData} from 'views/dashboards/util';

interface DashboardsGoalsOverviewProps {
  heatmapStatusSelectorRef: React.RefObject<HTMLDivElement>;
  timeframe: string;
  setSelectedStatus: (status: string) => void;
  dashboardsGoalCounts?: DashboardsGoalCounts;
}

export const DashboardsGoalsOverview = ({
  heatmapStatusSelectorRef,
  timeframe,
  setSelectedStatus,
  dashboardsGoalCounts,
}: DashboardsGoalsOverviewProps) => {
  return (
    <DashboardsGoalsOverviewComponent
      heatmapStatusSelectorRef={heatmapStatusSelectorRef}
      timeframe={timeframe}
      setSelectedStatus={setSelectedStatus}
      goalCounts={dashboardsGoalCounts?.total_counts}
    />
  );
};

export const DashboardsGoalsOverviewComponent = ({
  heatmapStatusSelectorRef,
  timeframe,
  setSelectedStatus,
  goalCounts,
}: {
  heatmapStatusSelectorRef: React.RefObject<HTMLDivElement>;
  timeframe: string;
  setSelectedStatus: (status: string) => void;
  goalCounts?: {[status: string]: number};
}) => {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <DashboardsChartContainer
      title={t('goals_overview')}
      subtitle={t('goals_overview_subtitle')}
      icon={TargetLine}
    >
      <Box marginTop="16" marginBottom="16">
        <DashboardsGoalsOverviewGraph
          timeframe={timeframe}
          height="87px"
          width="100%"
          onClick={(status) => {
            heatmapStatusSelectorRef.current?.scrollIntoView({block: 'center', behavior: 'smooth'});
            setSelectedStatus(status);
          }}
          goalCounts={goalCounts}
          showTooltip={!isMobile}
        />
      </Box>
      <Box
        as={Split}
        style={{
          gap: isMobile ? '12px' : '43px',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {DASHBOARD_STATUSES.map((status) => {
          const goalCountsData = getStatusGoalCountsData(status, goalCounts);
          return (
            <Box
              as="div"
              key={`dashboards-goals-overview-status-label__${status}`}
              alignItems="flex-start"
              display="flex"
              style={{gap: '4px'}}
            >
              <StatusButtonIcon
                status={status}
                statusColorOverride={getStatusColorOverride(
                  status,
                  DASHBOARDS_SEGMENT_STATUS_COLORS
                )}
              />
              <LabelGroup
                size="small"
                withText={
                  <Text size="small" isBold>
                    {t(status)}
                  </Text>
                }
                withSubtext={`${goalCountsData?.percentageRound ?? 0}%`}
              />
            </Box>
          );
        })}
      </Box>
    </DashboardsChartContainer>
  );
};
